import request from './Request.js';
import AppConfig from 'qs-config/AppConfig.js';
import { db } from 'qs-config/FirebaseConfig';
import * as Sentry from '@sentry/browser';
import { modifyPicturesUrlBackToS3, replaceCfWithS3 } from 'qs-helpers/PicturesProcessingHelper';

const updateFirebase = updates => db.ref().update(updates);

const getCatalogueIds = async () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/catalogue/all-catalogue-ids`);
};

const getBatchedCatalogueMeta = async catalogueIds => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v2/catalogue/catalogue-meta`, {
    catalogueIds
  });
};

const getProductTax = productId => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product/tax?productId=${productId}`);
};

const addProductTax = ({ productId, taxId } = {}) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/tax`, {
    productId,
    taxId
  });
};

const sendGeneratePDF = ({ generatePDFObject }) => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v2/catalogue/pdf/download`,
    generatePDFObject
  );
};

const removeProductTaxBulk = (productIds = []) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/tax/bulk`, {
    productIds
  });
};

const setProductTaxBulk = ({ productIds = [], taxId }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/tax/bulk`, {
    productIds,
    taxId
  });
};

const removeProductTax = ({ productId, taxId } = {}) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/tax`, { productId, taxId });
};

const deleteCatalogues = catalogueIds =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/catalogue`, { catalogueIds });

const updateCatalogue = ({ catalogueId, updates }) =>
  request.authPut(`${AppConfig.qsApiEndPoint}/v1/catalogue`, { catalogueId, updates });

const searchCatalogues = async searchTerm => {
  const client = 'DESKTOP';
  const version = 1;
  return request.authGet(
    `${AppConfig.searchEndPoint}/search/catalogue?query=${searchTerm}&client=${client}&version=${version}`,
    {}
  );
};

const createCatalogue = ({ title, catalogueId, labelIds } = {}) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue`, {
    title,
    catalogueId,
    labelIds
  });
};

// TODO this is already added in product API, remove from here and import from there wherever it is used
const getProductList = async catalogueId => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/product-list?catalogueId=${catalogueId}`
  );
};

const getBatchedProductList = async (productIds = []) => {
  const stringifiedProductIds = JSON.stringify(productIds);
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/product-meta`, {
    productIds: stringifiedProductIds
  });
};

const createShowCaseLink = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/showcase/link`, data);

// linkType can be NORMAL, RESELl
const createCataloguesLink = (catalogueIds, linkType = 'NORMAL', fetchHomepagePublished) => {
  return createShowCaseLink({
    catalogueIds,
    linkType,
    fetchHomepagePublished
  });
};

const createNewProducts = (catalogueId, products) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/catalogue-with-products`, {
    catalogueId,
    products
  });
};

const updateProduct = ({ productId, updates }) => {
  try {
    let revisedUpdates = { ...updates };
    if (typeof revisedUpdates.discount !== 'undefined') {
      revisedUpdates.discounted_price = revisedUpdates.discount;
      delete revisedUpdates.discount;
    }

    if (typeof revisedUpdates.pictureUrl === 'string') {
      revisedUpdates.pictureUrl = replaceCfWithS3(revisedUpdates.pictureUrl);
    }

    return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product`, {
      productId,
      updates: revisedUpdates
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const deleteProducts = (productIds, catalogueId) => {
  const data = [{ catalogueId, productIds }];
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/catalogues`, {
    data
  });
};

const catalogueScreenChanges = lastFetchDate => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/catalogues-screen/changes?lastFetchDate=${lastFetchDate}`
  );
};

const searchProducts = async (searchTerm = '', catalogueId, tags = []) => {
  const version = 1;
  const client = 'DESKTOP';
  const inStock = tags.indexOf('In stock') > -1;
  const outOfStock = tags.indexOf('Out of stock') > -1;
  const stringifiedArrays = JSON.stringify(tags);

  let data = null;

  if (inStock || outOfStock) {
    data = await searchWithStock({ catalogueId, inStock, outOfStock, tags, query: searchTerm });
  } else {
    const { data: searchData } = await request.authGet(
      `${AppConfig.searchEndPoint}/search/catalogue/product?query=${searchTerm}&client=${client}&version=${version}&catalogueId=${catalogueId}&tags=${stringifiedArrays}`,
      {}
    );
    data = searchData;
  }

  return data;
};

const filterProducts = async ({
  searchTerm,
  catalogueId,
  type,
  price = null,
  minPrice = null,
  maxPrice = null,
  tags
}) => {
  const version = 1;
  const client = 'DESKTOP';
  const inStock = tags.indexOf('In stock') > -1;
  const outOfStock = tags.indexOf('Out of stock') > -1;

  const stringifiedTags = JSON.stringify(tags);

  let data = null;
  if (inStock || outOfStock) {
    data = await searchWithStock({
      catalogueId,
      query: searchTerm,
      tags,
      outOfStock,
      inStock,
      filterType: type,
      price,
      minPrice,
      maxPrice
    });
  } else {
    const { data: searchData } = await request.authGet(
      `${AppConfig.searchEndPoint}/search/catalogue/product/filter?query=${searchTerm}&client=${client}&version=${version}&catalogueId=${catalogueId}&type=${type}&price=${price}&minPrice=${minPrice}&maxPrice=${maxPrice}&tags=${stringifiedTags}`
    );
    data = searchData;
  }

  return data;
};

const productsListScreenChanges = (lastFetchDate, catalogueId) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product/catalogue-detail-screen/changes?catalogueId=${catalogueId}&lastFetchDate=${lastFetchDate}`
  );
};

const getCatalogueTags = async catalogueId => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/getCatalogueTags?catalogueId=${catalogueId}`
  );
};

const getCatalogueTagsV2 = async catalogueId => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v2/catalogue/tags?catalogueId=${catalogueId}`);
};

const setCatalogueTagVisibility = async ({ catalogueId, tagId, visibility }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-tag/toggle-visibility`, {
    catalogueId,
    tagId,
    visibility
  });
};

const reorderCatalogueTags = ({ catalogueId, reorderedTags }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-tag/reorder`, {
    catalogueId,
    reorderedTags
  });
};

// PRIVATE NOTES APIs
const getPrivateNotes = productId => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product/private-notes?id=${productId}`);
};

const setPrivateNotes = (productIds, privateNotes) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/private-notes`, {
    productIds,
    privateNotes
  });
};

const getBasicInfo = async productId => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product?id=${productId}`);
};
const getGoldRatesAndProductType = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product-type-rate/all`);
};
const setProductStoneDetails = formData => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-type-rate-charges`, formData);
};
const updateProductStoneDetails = formData => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product-type-rate-charges`, formData);
};
const deleteProductStoneDetails = ({ productId, id }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product-type-rate-charges`, {
    productId,
    id
  });
};

// PRODUCT TAGS APIs
const createTagInProduct = (catalogueId, productIds, tagId) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-tag/add`, {
    catalogueId,
    productIds,
    tagId
  });
};

const deleteProductTag = ({ productIds, tagId }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product-tag/delete-tags`, {
    productIds,
    tagId
  });
};

const reorderTags = ({ productId, reorderedTags }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-tag/reorder`, {
    productId,
    reorderedTags
  });
};

const getCatalogueSettings = catalogueId => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/experiments?catalogueId=${catalogueId}`
  );
};

const changeCatalogueSettings = ({ catalogueId, changes }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/experiments`, {
    catalogueId,
    changes
  });
};

const changeOutOfStockVisibility = ({ catalogueId, outOfStockVisibility }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/out-of-stock-visibility`, {
    catalogueId,
    outOfStockVisibility
  });
};

const changeOrderOnOutOfStock = ({ catalogueId, allowOrderOnOutOfStock }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/allow-order-on-out-of-stock`, {
    catalogueId,
    allowOrderOnOutOfStock
  });
};

const getCompanySettings = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/settings`);
};

const getCataloguePrivacyAccessSettings = (entityType, entityId) => {
  const queryParamsStringForCatalogueLink = request.createQueryParams([
    {
      key: 'entityType',
      value: entityType
    },
    {
      key: 'entityId',
      value: entityId
    }
  ]);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/entity-filter/access-level${queryParamsStringForCatalogueLink}`
  );
};

const setCataloguePrivacyAccessSettings = body => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/entity-filter/access-level`, body);
};

const getCatalogueShareText = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/custom-share-text`);

const getCompanyPlanSettings = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v3/company/plan`);
};

const getCompanyProductSettings = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/request-demo`);
};

const getConfigDemoLink = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/config/request-demo-link`);
};

const downloadCatalogueBrochure = catalogueId => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/brochure/download?catalogueId=${catalogueId}`
  );
};

const multiCatalogueBrochure = brochureData => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/multi-catalogue-brochure`,
    brochureData
  );
};

const downloadCataloguePdf = ({ catalogueId, catalogueLink }) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/pdf/download?catalogueId=${catalogueId}&catalogueLink=${catalogueLink}`
  );
};

const downloadCatalogueImages = ({ catalogueId }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/images/download`, {
    catalogueId
  });
};

const downloadCatalogueAsExcel = ({ catalogueId }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v2/catalogue/excel/download`, {
    catalogueId
  });
};

const getCatalogueLink = async ({ catalogueId }) => {
  const queryParamsStringForCatalogueLink = request.createQueryParams([
    {
      key: 'catalogueId',
      value: catalogueId
    },
    {
      key: 'fetchHomepagePublished',
      value: true
    }
  ]);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/showcase/get-catalogue-link${queryParamsStringForCatalogueLink}`
  );
};

const reorderProductInRemote = ({ productsList, catalogueId }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/reorder`, {
    products: productsList,
    catalogueId
  });
};

const getInventory = productId => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product/inventory?id=${productId}`);
};

const setStockCount = ({ productId, stockCount }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/inventory/app`, {
    productId,
    stock: stockCount
  });
};

const autoReduceQuantity = ({ productIds, value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/auto-reduce-quantity`, {
    productIds,
    autoReduceQuantity: value
  });
};

const setInventoryTracking = ({ productIds, value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/track-inventory`, {
    productIds,
    trackInventory: value
  });
};

const setOutOfStockVisibility = ({ productIds, value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/out-of-stock-visibility`, {
    productIds,
    outOfStockVisibility: value
  });
};

const setAllowOrderOnOutOfStock = ({ productIds, value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/allow-order-on-out-of-stock`, {
    productIds,
    allowOrderOnOutOfStock: value
  });
};

const bulkUpdateProducts = ({ productIds, updates }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product/bulk`, {
    productIds,
    updates
  });
};

const bulkUpdateProductsPrice = ({ productIds, price }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v3/product/price/bulk`, {
    productIds,
    price
  });
};

const bulkUpdatePriceAndDiscount = ({ productIds, discountInPercent }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v2/product/price/bulk`, {
    productIds,
    discountInPercent
  });
};

const deleteProductPicture = ({ productId, pictureIds }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictureIds
  });
};

const getSlabPricesForProduct = ({ productId }) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/product/slab-prices?productId=${productId}`
  );
};

const setSlabPricesForProduct = ({ productId, slabPrices }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product/slab-prices`, {
    productIds: [productId],
    slabPrices
  });
};

const deleteSlabPricesForProduct = ({ productId }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product/slab-prices`, {
    productIds: [productId]
  });
};

const setBulkStockCount = ({ productIds, stock }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/inventory/app/bulk`, {
    productIds,
    stock
  });
};

const changeCompanyOutOfStockVisibility = ({ value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/out-of-stock-visibility`, {
    outOfStockVisibility: value
  });
};

const changeCompanyAllowOrderOnOutOfStock = ({ value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/allow-order-on-out-of-stock`, {
    allowOrderOnOutOfStock: value
  });
};

const changeCompanyTrackInventory = ({ value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/track-inventory`, {
    trackInventory: value
  });
};

const changeCompanyAutoReduceInventory = ({ value }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/auto-reduce-quantity`, {
    autoReduceQuantity: value
  });
};

const updateProductPicture = ({ productId, pictures }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures
  });
};

const login = auth => request.authPost(`${AppConfig.qsApiEndPoint}/v1/auth/login`, auth);

const saveCompanyDetails = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/save-company-details`, data);

const getPlanFromRemote = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v7/payment-plan`);
};

const startFreeTrial = data => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v2/purchase/trial`, data);
};

const getWhatsappNumber = () =>
  request.post(`${AppConfig.qsApiEndPoint}/v1/auth/whatsapp/code`, {});

const getWhatsappLoginToken = data =>
  request.post(`${AppConfig.qsApiEndPoint}/v1/auth/whatsapp/verify`, data);

const addNewPicture = ({ pictures, productId }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures: modifyPicturesUrlBackToS3(pictures)
  });
};

const updateExistingPicture = ({ productId, pictures }) => {
  return request.authPut(`${AppConfig.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures
  });
};

const getCurrentPlan = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v3/company/plan-features?storeType=DESKTOP`);
};
const getProductType = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/product-type-rate/all`);
};
const getPaymentPlan = () => request.authGet(`${AppConfig.qsApiEndPoint}/v2/plan-feature/desktop`);

const getCustomerPlan = companyId => {
  return db
    .ref(`paid-companies-meta/${companyId}/paymentPlan`)
    .once('value')
    .then(snapshot => snapshot.val());
};

const fetchExtendedTrialDuration = companyId => {
  return db
    .ref(`companies-trial-extended/${companyId}/trialStartTime`)
    .once('value')
    .then(snapshot => snapshot.val());
};

// ORDER

const getOrdersContacts = ({ catalogueId = 0 } = {}) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/order/contacts?catalogueId=${encodeURIComponent(catalogueId)}`
  );
};

const getContactOrders = ({ catalogueId = '', contact } = {}) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v2/order/contact-orders?catalogueId=${encodeURIComponent(
      catalogueId
    )}&contact=${encodeURIComponent(contact)}`
  );
};

// TODO remove from this file
const getOrderInquiries = ({ orderId } = {}) => {
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v4/order/inquiries?orderId=${encodeURIComponent(
      orderId
    )}&parseText=${true}`
  );
};

const getOrdersContactsMeta = ({ catalogueId = '', contacts } = {}) => {
  return request.authPost(
    `${AppConfig.qsApiEndPoint}/v1/order/contacts-meta?catalogueId=${encodeURIComponent(
      catalogueId
    )}`,
    { contacts }
  );
};

const downloadContactsOrderCsv = ({ catalogueId, contacts, timezone = '' }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/contacts/excel/download/zip`, {
    catalogueId,
    contacts,
    timezone
  });
};

const deleteOrders = ({ orderIds }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/order/app/multi`, { orderIds });
};
// TODO remove from this file
const deleteInquiries = ({ inquiryIds }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/inquiry/app`, { inquiryIds });
};

const readInquiries = ({ inquiryIds }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/showcase-visitor-meta/read/inquiries`, {
    inquiryIds
  });
};

const deleteContacts = ({ contacts, catalogueId }) => {
  return request.authDelete(`${AppConfig.qsApiEndPoint}/v1/order/contacts/app/multi`, {
    contacts,
    catalogueId
  });
};

// TODO remove from ehre
const downloadExcel = orderId => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/excel/download`, {
    orderId: orderId
  });
};

// TODO remove from ehrer
const downloadPDF = orderId => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/pdf/download`, { orderId: orderId });
};

// TODO remove from this file
const downloadZip = orderId => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/images/download`, {
    orderId: orderId
  });
};

// TODO remove from this file
const sendOrderToVendor = orderId => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/order/vendor`, { orderId });
};

const getShowCaseIdForResell = url => {
  return new Promise(resolve => {
    return db.ref(`domainMap/${url}`).on('value', snapshot => {
      db.ref(`domainMap/${url}`).off('value');
      resolve(snapshot.val());
    });
  });
};

const getCatalogId = showcaseId =>
  new Promise(resolve =>
    db.ref(`showcases/${showcaseId}/catalogueId`).on('value', snapshot => {
      db.ref(`showcases/${showcaseId}/catalogueId`);
      resolve(snapshot.val());
    })
  );

const getCatalogIdFromShowcaseUrlViaRds = url => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/showcase/catalogue-id?slug=${url}`);
};

const getLinks = async productList => {
  const productIds = Object.keys(productList);

  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/firebase-format`, {
    productIds
  });
};

const getCompanyName = async companyId => {
  const { name } = await request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/company/name?companyId=${companyId}`
  );
  return name;
};

const duplicateCatalogue = catalogueId => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/resell/duplicate-catalogue`, {
    catalogueId: catalogueId
  });
};

const getResellersProductMeta = async catalogueId => {
  const catalogue = await request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue/firebase-format?catalogueId=${catalogueId}`
  );
  return catalogue;
};

const getBulkProductTags = productIds => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue-tag/bulk-tags`, {
    productIds
  });
};

const searchWithStock = ({
  catalogueId,
  inStock,
  outOfStock,
  tags,
  query,
  filterType,
  price,
  minPrice,
  maxPrice
}) => {
  const client = 'DESKTOP';
  const version = 1;
  const stringifiedTags = JSON.stringify(tags);
  return request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/catalogue-tag/search-products-stock?catalogueId=${catalogueId}&inStock=${inStock}&outOfStock=${outOfStock}&tags=${stringifiedTags}&query=${query}&client=${client}&version=${version}&filterType=${filterType}&price=${price}&minPrice=${minPrice}&maxPrice=${maxPrice}`
  );
};

const uploadExcel = ({ productsMeta, keyForMapping, catalogueId, uuid }) => {
  return request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/excel`, {
    productsMeta,
    keyForMapping,
    catalogueId,
    uuid
  });
};

const getCompanyAndUserObject = () => {
  return request.authGet(`${AppConfig.qsApiEndPoint}/v1/company/company-user-object`);
};

const updateCompanySettings = (value, companyId) =>
  db.ref(`company-experiments/${companyId}`).update(value);

const updateCompanyCatalogueProductCountSettings = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/experiment/field-value`, data);

const getAppLanguage = companyId =>
  request.authGet(
    `${AppConfig.qsApiEndPoint}/v1/experiment/field-value?companyId=${companyId}&field=appI18n`
  );

const setAppLanguage = (companyId, languageCode = 'en_US') =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/experiment/field-value`, {
    entityId: companyId,
    entityType: 'COMPANY',
    field: 'appI18n',
    value: languageCode
  });

const getCountryCode = () => request.authGet(`${AppConfig.qsApiEndPoint}/v1/config/ip-geo`);

const submitFeedback = ({
  feedbackData,
  companyId,
  userId,
  userName,
  phoneNumber,
  companyName
}) => {
  const keyFromFirebase = db
    .ref()
    .child('posts')
    .push().key;
  return db.ref(`android-user-feedback/${keyFromFirebase}`).update({
    id: keyFromFirebase,
    company_id: companyId,
    user_id: userId,
    feedback: feedbackData,
    user_name: userName,
    company_name: companyName,
    phone_no: phoneNumber
  });
};

const updateCompanyShareText = (value, companyId) =>
  db.ref(`custom-share-text/${companyId}`).update(value);

const updateUserMetaData = (value, userId) => db.ref(`users/${userId}`).update(value);

const updateCompanyMetaData = (value, companyId) => db.ref(`companies/${companyId}`).update(value);

const updateCompanyAddress = (value, companyId) =>
  db.ref(`companies/${companyId}/address`).update(value);

const updateProductPicturesError = pictureIds =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/errored`, { pictureIds });

const addProductPictures = requestBody =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/add-replace-products`, requestBody);

const deleteErroredCatalogueProducts = catalogueId =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/catalogue/errored`, { catalogueId });

const markPendingImagesToError = uuid =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/catalogue/unprepared-errored`, { uuid });

const skuExcelUpload = body =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/sku-excel-upload`, body);

const getAllCustomFields = id => request.authGet(`${AppConfig.qsApiEndPoint}/v1/product-field`);

const getProductCustomFields = id =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/product-field/product?productId=${id}`);

const setProductCustomFieldData = data =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-field/value`, data);

const deleteProductCustomField = data =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/product-field/value`, data);

const excelUploadChanges = body =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/excel-changes-meta`, body);

const setMinOrderAmt = body =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/experiment/enable-min-order-val`, body);

const getMinOrderAmt = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v1/experiment/min-order-val`);

const disableMinOrderAmt = () =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/experiment/disable-min-order-val`);
const setFirstProductCover = catalogueId =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/experiment/first-product-cover/${catalogueId}`);

const removeFirstProductCover = catalogueId =>
  request.authDelete(`${AppConfig.qsApiEndPoint}/v1/experiment/first-product-cover/${catalogueId}`);

const getBestSelectorAndUpsertCount = ({ excelRows, catalogueId }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product/excel-changes-without-selector`, {
    excelRows,
    catalogueId
  });

const getCurrentServerTime = () => request.authGet(`${AppConfig.qsApiEndPoint}/v2/time`);

const updateOrderReceiptsSetting = receiveOrderOnWhatsapp =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/whatsapp/receive-order-on-whatsapp`, {
    receiveOrderOnWhatsapp
  });

const duplicateProductsToCatalogue = ({ catalogueId, productIds, shouldCreate, title }) =>
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/product-library/catalogue-products/duplicate`, {
    catalogueId,
    productIds,
    shouldCreate,
    catalogueTitle: title
  });

const updateBulkOrderingViewSetting = values => {
  request.authPost(`${AppConfig.qsApiEndPoint}/v1/company/settings`, {
    settings: {
      bulkOrderingView: values
    }
  });
};

const getCompanyUnitDetails = () =>
  request.authGet(`${AppConfig.qsApiEndPoint}/v3/company/company-unit-details`);

export default {
  updateFirebase,
  getCatalogueIds,
  getBatchedCatalogueMeta,
  deleteCatalogues,
  updateCatalogue,
  searchCatalogues,
  createCatalogue,
  getProductList,
  createShowCaseLink,
  createCataloguesLink,
  getBatchedProductList,
  deleteProducts,
  createNewProducts,
  updateProduct,
  catalogueScreenChanges,
  searchProducts,
  filterProducts,
  productsListScreenChanges,
  getCatalogueTags,
  getCatalogueTagsV2,
  getBulkProductTags,
  setCatalogueTagVisibility,
  reorderCatalogueTags,
  getPrivateNotes,
  setPrivateNotes,
  getBasicInfo,
  createTagInProduct,
  deleteProductTag,
  reorderTags,
  getCatalogueSettings,
  changeCatalogueSettings,
  changeOutOfStockVisibility,
  changeOrderOnOutOfStock,
  getCompanySettings,
  getCatalogueShareText,
  getCompanyPlanSettings,
  getCompanyProductSettings,
  getConfigDemoLink,
  downloadCatalogueBrochure,
  downloadCataloguePdf,
  downloadCatalogueImages,
  downloadCatalogueAsExcel,
  getCatalogueLink,
  reorderProductInRemote,
  getInventory,
  setStockCount,
  autoReduceQuantity,
  setInventoryTracking,
  setOutOfStockVisibility,
  setAllowOrderOnOutOfStock,
  bulkUpdateProducts,
  bulkUpdateProductsPrice,
  bulkUpdatePriceAndDiscount,
  deleteProductPicture,
  setBulkStockCount,
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  updateProductPicture,
  login,
  saveCompanyDetails,
  getPlanFromRemote,
  startFreeTrial,
  getWhatsappNumber,
  getWhatsappLoginToken,
  addNewPicture,
  updateExistingPicture,
  getCurrentPlan,
  getPaymentPlan,
  getCustomerPlan,
  fetchExtendedTrialDuration,

  // SLAB PRICES
  getSlabPricesForProduct,
  setSlabPricesForProduct,
  deleteSlabPricesForProduct,

  // ORDER
  getOrdersContacts,
  getContactOrders,
  getOrderInquiries,
  getOrdersContactsMeta,
  downloadContactsOrderCsv,
  deleteOrders,
  deleteInquiries,
  readInquiries,
  deleteContacts,
  downloadExcel,
  downloadPDF,
  downloadZip,
  sendOrderToVendor,
  getShowCaseIdForResell,
  getCatalogId,
  getCatalogIdFromShowcaseUrlViaRds,
  getLinks,
  getCompanyName,
  duplicateCatalogue,
  getResellersProductMeta,
  searchWithStock,
  getCompanyAndUserObject,

  // COMPANY SETTINGS
  updateOrderReceiptsSetting,
  updateCompanySettings,
  updateCompanyCatalogueProductCountSettings,
  getAppLanguage,
  setAppLanguage,
  updateCompanyShareText,
  submitFeedback,
  getCompanyUnitDetails,

  // CONFIG
  getCountryCode,

  // USER
  updateUserMetaData,

  // COMPANIES
  updateCompanyMetaData,
  updateCompanyAddress,

  // TAXES
  addProductTax,
  getProductTax,
  removeProductTaxBulk,
  removeProductTax,
  setProductTaxBulk,

  updateProductPicturesError,
  addProductPictures,
  deleteErroredCatalogueProducts,
  markPendingImagesToError,
  multiCatalogueBrochure,

  // MOA
  setMinOrderAmt,
  getMinOrderAmt,
  disableMinOrderAmt,

  setFirstProductCover,
  removeFirstProductCover,

  // EXCEL
  skuExcelUpload,
  uploadExcel,
  getBestSelectorAndUpsertCount,

  // CUSTOM FIELDS
  getAllCustomFields,
  getProductCustomFields,
  setProductCustomFieldData,
  deleteProductCustomField,
  excelUploadChanges,

  getCurrentServerTime,
  duplicateProductsToCatalogue,
  updateBulkOrderingViewSetting,

  // PDF
  sendGeneratePDF,

  // METAL RATES
  getGoldRatesAndProductType,

  // STONE CHARGES
  setProductStoneDetails,
  updateProductStoneDetails,
  deleteProductStoneDetails,

  // PRIVACY SETTINGS
  getCataloguePrivacyAccessSettings,
  setCataloguePrivacyAccessSettings,

  getProductType
};
