import React, { useEffect, useState, useMemo } from 'react';
import Loader from '../Loader';
import { ReactComponent as UploadIcon } from 'qs-assets/Media/icon_upload.svg';
import '../GlobalLoader/styles.css';
import { convertImageUrlSize } from 'qs-helpers/index';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import { IMAGE_LOADER, IMAGE_ERROR, IMAGE_HIDE_LOADER } from 'qs-helpers/PicturesProcessingHelper';
import './styles.scss';

export default ({
  url,
  pictureId,
  imageContainerClassName = '',
  imageClassName = '',
  isPrepared = true,
  error = false,
  pictureCount = 0,
  videoCount = 0,
  loaderSize = 'small',
  onClick
}) => {
  const isImageStateUploading = (imagePrepared, imageError) => {
    if (imagePrepared || imageError) {
      return false;
    }
    return true;
  };

  const imageUrl = useMemo(() => convertImageUrlSize({ size: '100', url }), [url]);

  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();
  const [isUploading, setIsUploading] = useState(() => isImageStateUploading(isPrepared, error));

  useEffect(() => {
    setIsUploading(isImageStateUploading(isPrepared, error));
  }, [isPrepared, error]);

  useEffect(() => {
    updateTimeoutRefForPictures([{ id: pictureId }]);
  }, [pictureId, updateTimeoutRefForPictures]);

  const getMediaCount = () => {
    if (typeof pictureCount && videoCount !== 'number' && pictureCount <= 1 && videoCount <= 0) {
      return null;
    }

    return (
      <div className={'pictureCountContainer'}>
        <span>{pictureCount + videoCount}</span>
      </div>
    );
  };

  const renderImageOrUploader = () => {
    const { state = IMAGE_LOADER, retryCount = 0 } = picturesLoadState[pictureId] || {};

    if (error || state === IMAGE_ERROR || imageUrl === null) {
      return (
        <div className={'errorIconContainer'}>
          <ErrorIcon className={'errorIcon'} />
        </div>
      );
    }

    if (isUploading) {
      return (
        <div className={'absolutePosition uploadingImageContainer'}>
          <UploadIcon className="uploadingImage" />
          <div className="progress">
            <div className="indeterminate" />
          </div>
        </div>
      );
    }

    let clickHandler,
      imageContainerClass = `imageContainer ${imageClassName}`;
    if (typeof onClick === 'function') {
      clickHandler = onClick;
      imageContainerClass += ` clickable`;
    }

    return (
      <>
        {state === IMAGE_LOADER && (
          <div className={'absolutePosition'}>
            <Loader size={loaderSize} />
          </div>
        )}
        <div className={imageContainerClass} onClick={clickHandler}>
          <div
            key={`${pictureId}-background-${retryCount}`}
            className={'smallBg'}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
          <img
            key={`${pictureId}-${retryCount}`}
            onError={handleImageError(pictureId, IMAGE_ERROR)}
            onLoad={handleImageLoad(pictureId, IMAGE_HIDE_LOADER)}
            src={`${imageUrl}`}
            className={'smallImage'}
            alt={''}
          />
        </div>
      </>
    );
  };

  let containerClassName = 'smallImageLoader';
  if (imageContainerClassName) {
    containerClassName += ' ' + imageContainerClassName;
  }

  return (
    <div id={'smallImageLoader'} className={containerClassName}>
      {renderImageOrUploader()}
      {getMediaCount()}
    </div>
  );
};
