import eventbus from 'eventing-bus';
import { ROUTE_MAP } from 'qs-helpers/CompanySettings/constants';

let NAVIGATION_REF = null;

const setNavigationRef = ref => {
  NAVIGATION_REF = ref;
};

const getNavigationRef = () => NAVIGATION_REF;

const navigateToPath = path => {
  NAVIGATION_REF.history.push(path);
};

let SIDE_BARS_META = {
  EVENT_BUS: 'SIDE_BAR_META',
  showSideBar: false
};

const GRT_PM_META = {
  EVENT_BUS_KEY: 'GRT_PM_META_EVENTBUS',
  isGrtPM: null
};

const sideBarStateOnMount = () => {
  const shouldShow =
    !window.location.href.includes(`/${ROUTE_MAP.LOGIN}`) && !(window.location.pathname === '/');
  SIDE_BARS_META.showSideBar = shouldShow;
  return shouldShow;
};

const showSideBars = () => {
  SIDE_BARS_META.showSideBar = true;
  eventbus.publish(SIDE_BARS_META.EVENT_BUS, true);
};
const hideSideBars = () => {
  SIDE_BARS_META.showSideBar = false;
  eventbus.publish(SIDE_BARS_META.EVENT_BUS, false);
};

const setGrtPmInCache = isGrtPm => {
  GRT_PM_META.isGrtPM = isGrtPm;
};

export {
  setNavigationRef,
  getNavigationRef,
  navigateToPath,
  SIDE_BARS_META,
  sideBarStateOnMount,
  showSideBars,
  hideSideBars,
  GRT_PM_META,
  setGrtPmInCache
};
