import { LOCAL_STORAGE_KEYS } from 'qs-services/Rehydration';

const currentPlanFeatures =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN)) || false;
const allPlans = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FEATURE_LIMIT)) || false;

export const isFeatureAllowedForPlan = id => {
  const currentPlan = currentPlanFeatures && currentPlanFeatures.features[id];

  if (!!currentPlan && !!currentPlan.allowed) {
    return { allowed: !!currentPlan.allowed };
  }
  return {
    allowed: !!currentPlan && !!currentPlan.allowed,
    allowedFor: featureAllowedFor(id)
  };
};

export const featureAllowedFor = id => {
  const featureAllowedForPlans = [];
  allPlans &&
    Array.isArray(allPlans.plans) &&
    allPlans.plans.length > 0 &&
    allPlans.plans.map(plan => {
      Array.isArray(plan.features) &&
        plan.features.length > 0 &&
        plan.features.map(feature => {
          if (feature.feature === id && feature.options.allowed) {
            featureAllowedForPlans.push(plan.shortDisplayName);
          }
          return featureAllowedForPlans;
        });
      return featureAllowedForPlans;
    });
  return featureAllowedForPlans;
};

export const PAID_FEATURE = {
  PURE_WHITELABEL: {
    id: 'PURE_WHITELABEL',
    isAllowed: isFeatureAllowedForPlan('PURE_WHITELABEL')
  },
  CATALOGUES: {
    id: 'CATALOGUES',
    isAllowed: isFeatureAllowedForPlan('CATALOGUES')
  },
  PRODUCTS: {
    id: 'PRODUCTS',
    isAllowed: isFeatureAllowedForPlan('PRODUCTS')
  },
  DESKTOP: {
    id: 'DESKTOP',
    isAllowed: isFeatureAllowedForPlan('DESKTOP')
  },
  ORDERS: {
    id: 'ORDERS',
    isAllowed: isFeatureAllowedForPlan('ORDERS')
  },
  VISITORS: {
    id: 'VISITORS',
    isAllowed: isFeatureAllowedForPlan('VISITORS')
  },
  CUSTOMER_EXPORT: {
    id: 'CUSTOMER_EXPORT',
    isAllowed: isFeatureAllowedForPlan('CUSTOMER_EXPORT')
  },
  ORDER_EXPORT: {
    id: 'ORDER_EXPORT',
    isAllowed: isFeatureAllowedForPlan('ORDER_EXPORT')
  },
  SHARE: {
    id: 'SHARE',
    isAllowed: isFeatureAllowedForPlan('SHARE')
  },
  SHARE_VIA_BROCHURE: {
    id: 'SHARE_VIA_BROCHURE',
    isAllowed: isFeatureAllowedForPlan('SHARE_VIA_BROCHURE')
  },
  RESELL: {
    id: 'RESELL',
    isAllowed: isFeatureAllowedForPlan('RESELL')
  },
  SHARE_VIA_PDF: {
    id: 'SHARE_VIA_PDF',
    isAllowed: isFeatureAllowedForPlan('SHARE_VIA_PDF')
  },
  INVENTORY_MANAGEMENT: {
    id: 'INVENTORY_MANAGEMENT',
    isAllowed: isFeatureAllowedForPlan('INVENTORY_MANAGEMENT')
  },
  VISITOR_ANALYTICS: {
    id: 'VISITOR_ANALYTICS',
    isAllowed: isFeatureAllowedForPlan('VISITOR_ANALYTICS')
  },
  PRIVATE_NOTES: {
    id: 'PRIVATE_NOTES',
    isAllowed: isFeatureAllowedForPlan('PRIVATE_NOTES')
  },
  PAYMENTS: {
    id: 'PAYMENTS',
    isAllowed: isFeatureAllowedForPlan('PAYMENTS')
  },
  CHANGE_THEME: {
    id: 'CHANGE_THEME',
    isAllowed: isFeatureAllowedForPlan('CHANGE_THEME')
  },
  CHANGE_ACTION_BUTTON: {
    id: 'CHANGE_ACTION_BUTTON',
    isAllowed: isFeatureAllowedForPlan('CHANGE_ACTION_BUTTON')
  },
  CATALOGUE_SEARCH: {
    id: 'CATALOGUE_SEARCH',
    isAllowed: isFeatureAllowedForPlan('CATALOGUE_SEARCH')
  },
  PRODUCT_SEARCH: {
    id: 'PRODUCT_SEARCH',
    isAllowed: isFeatureAllowedForPlan('PRODUCT_SEARCH')
  },
  PRICE_FILTERS: {
    id: 'PRICE_FILTERS',
    isAllowed: isFeatureAllowedForPlan('PRICE_FILTERS')
  },
  HEADLINE: {
    id: 'HEADLINE',
    isAllowed: isFeatureAllowedForPlan('HEADLINE')
  },
  PRODUCT_TAGS: {
    id: 'PRODUCT_TAGS',
    isAllowed: isFeatureAllowedForPlan('PRODUCT_TAGS')
  },
  MULTI_CATALOGUE_LINK: {
    id: 'MULTI_CATALOGUE_LINK',
    isAllowed: isFeatureAllowedForPlan('MULTI_CATALOGUE_LINK')
  },
  QR_CODE: {
    id: 'QR_CODE',
    isAllowed: isFeatureAllowedForPlan('QR_CODE')
  },
  SUPPORT: {
    id: 'SUPPORT',
    isAllowed: isFeatureAllowedForPlan('SUPPORT')
  },
  REQUEST_VISITOR_IDENTITY: {
    id: 'REQUEST_VISITOR_IDENTITY',
    isAllowed: isFeatureAllowedForPlan('REQUEST_VISITOR_IDENTITY')
  },
  ENABLE_OTP_VERIFICATION: {
    id: 'ENABLE_OTP_VERIFICATION',
    isAllowed: isFeatureAllowedForPlan('ENABLE_OTP_VERIFICATION')
  },
  PRIVACY_SETTINGS: {
    id: 'PRIVACY_SETTINGS',
    isAllowed: isFeatureAllowedForPlan('PRIVACY_SETTINGS')
  },
  BRANDING_LOGO: {
    id: 'BRANDING_LOGO',
    isAllowed: isFeatureAllowedForPlan('BRANDING_LOGO')
  },
  SMART_VISITOR_FILTERS: {
    id: 'SMART_VISITOR_FILTERS',
    isAllowed: isFeatureAllowedForPlan('SMART_VISITOR_FILTERS')
  },
  PRODUCT_POPULARITY: {
    id: 'PRODUCT_POPULARITY',
    isAllowed: isFeatureAllowedForPlan('PRODUCT_POPULARITY')
  },
  DEVICE: {
    id: 'DEVICE',
    isAllowed: isFeatureAllowedForPlan('DEVICE')
  },
  DISCOUNT: {
    id: 'DISCOUNT',
    isAllowed: isFeatureAllowedForPlan('DISCOUNT')
  },
  BRANDING_DOMAIN: {
    id: 'BRANDING_DOMAIN',
    isAllowed: isFeatureAllowedForPlan('BRANDING_DOMAIN')
  },
  COUPON: {
    id: 'COUPON',
    isAllowed: isFeatureAllowedForPlan('COUPON')
  },
  VARIANT: {
    id: 'VARIANT',
    isAllowed: isFeatureAllowedForPlan('VARIANT')
  },
  TAX: {
    id: 'TAX',
    isAllowed: isFeatureAllowedForPlan('TAX')
  },
  CONCIERGE_ONBOARDING: {
    id: 'CONCIERGE_ONBOARDING',
    isAllowed: isFeatureAllowedForPlan('CONCIERGE_ONBOARDING')
  },
  BUSINESS_CONSULTATION: {
    id: 'BUSINESS_CONSULTATION',
    isAllowed: isFeatureAllowedForPlan('BUSINESS_CONSULTATION')
  },
  VIP_SUPPORT: {
    id: 'VIP_SUPPORT',
    isAllowed: isFeatureAllowedForPlan('VIP_SUPPORT')
  },
  CUSTOM_INTEGRATIONS: {
    id: 'CUSTOM_INTEGRATIONS',
    isAllowed: isFeatureAllowedForPlan('CUSTOM_INTEGRATIONS')
  },
  PRIVACY_MODULE: {
    id: 'PRIVACY_MODULE',
    isAllowed: isFeatureAllowedForPlan('PRIVACY_MODULE')
  },
  SET_WISE_ORDERING: {
    id: 'SET_WISE_ORDERING',
    isAllowed: isFeatureAllowedForPlan('SET_WISE_ORDERING')
  },
  BULK_ORDERING: {
    id: 'BULK_ORDERING',
    isAllowed: isFeatureAllowedForPlan('BULK_ORDERING')
  },
  MINIMUM_ORDER_QUANTITY: {
    id: 'MINIMUM_ORDER_QUANTITY',
    isAllowed: isFeatureAllowedForPlan('MINIMUM_ORDER_QUANTITY')
  },
  HIDE_CREATE_CATALOGUE_BANNER: {
    id: 'HIDE_CREATE_CATALOGUE_BANNER',
    isAllowed: isFeatureAllowedForPlan('HIDE_CREATE_CATALOGUE_BANNER')
  },
  CATALOGUE_LABELS: {
    id: 'CATALOGUE_LABELS',
    isAllowed: isFeatureAllowedForPlan('CATALOGUE_LABELS')
  },
  THEMES_MANAGER: {
    id: 'THEMES_MANAGER',
    isAllowed: isFeatureAllowedForPlan('THEMES_MANAGER')
  },
  CUSTOM_FIELDS: {
    id: 'CUSTOM_FIELDS',
    isAllowed: isFeatureAllowedForPlan('CUSTOM_FIELDS')
  },
  CUSTOM_CHECKOUT_FIELDS: {
    id: 'CUSTOM_CHECKOUT_FIELDS',
    isAllowed: isFeatureAllowedForPlan('CUSTOM_CHECKOUT_FIELDS')
  },
  DELIVERY_SHIPROCKET: {
    id: 'DELIVERY_SHIPROCKET',
    isAllowed: isFeatureAllowedForPlan('DELIVERY_SHIPROCKET')
  },
  PRODUCT_VIDEO: {
    id: 'PRODUCT_VIDEO',
    isAllowed: isFeatureAllowedForPlan('PRODUCT_VIDEO')
  },
  PUBLIC_KEY: {
    id: 'PUBLIC_KEY',
    isAllowed: isFeatureAllowedForPlan('PUBLIC_KEY')
  },
  WEBHOOKS: {
    id: 'WEBHOOKS',
    isAllowed: isFeatureAllowedForPlan('WEBHOOKS')
  }
};
