import React, { useRef, useState, useCallback, useContext, useReducer, useMemo } from 'react';
import Header from './Header';
import Loader from '../../Common/Loader';
import Footer from './Footer';
import OrderProductsView from './OrderProductsView';
import { ActiveOrderMeta, OrderNoteContext } from '../context';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchOrderProductsHandler,
  handleOrderChange,
  getCachedOrderProductsData
} from 'qs-helpers/Orders/DataQueryHelper';
import { activeOrderViewInit, activeOrderViewReducer } from './reducer';
import { ActiveOrderView } from './context';
import usePreviousHook from 'qs-hooks/usePreviousHook';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const containerRef = useRef(null);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const {
    activeOrderMeta: { selectedOrderId }
  } = useContext(ActiveOrderMeta);

  const [activeOrderView, setActiveOrderViewData] = useReducer(
    activeOrderViewReducer,
    undefined,
    activeOrderViewInit
  );

  const orderViewContext = useMemo(
    () => ({
      activeOrderView,
      setActiveOrderViewData
    }),
    [activeOrderView, setActiveOrderViewData]
  );

  const { t } = getI18N();

  const [marginScrollOffset, setMarginScrollOffset] = useState(0);
  const [{ loading, error, data: orderInquiry }] = useMakeQuery({
    changeDependancy: [selectedOrderId],
    actionHandler: fetchOrderProductsHandler,
    cleanupHandler: handleOrderChange,
    cachedDataHandler: getCachedOrderProductsData
  });

  const onListMount = useCallback(
    listWidth => {
      if (!containerRef.current) {
        return;
      }

      setMarginScrollOffset(containerRef.current.clientWidth - listWidth);
    },
    [setMarginScrollOffset]
  );

  const [previousOrderId] = usePreviousHook({ values: [selectedOrderId] });

  const getOrderDetailsComponent = () => {
    if (!selectedOrderId) {
      return (
        <div className="orderDetailsScreenPlaceholder">{t('select_an_order_to_view_products')}</div>
      );
    }

    if (error) {
      return (
        <div className="orderDetailsScreenPlaceholder">
          {t('something_went_wrong_while_fetching_this_order_data')}
        </div>
      );
    }

    // Order id has changed hide the components, as the new data will be fetched
    // when the new values are set the previous value will also be set to the current value
    if (!orderInquiry || loading || previousOrderId !== selectedOrderId) {
      return (
        <div className="orderDetailsScreenPlaceholder">
          <Loader size="large" />
        </div>
      );
    }

    return (
      <>
        <Header
          displayOrderId={orderInquiry.displayOrderId}
          paymentCaptured={orderInquiry.paymentCaptured}
          paymentFailed={orderInquiry.paymentFailed}
          paymentPending={orderInquiry.paymentPending}
          marginScrollOffset={marginScrollOffset}
          selectedOrderId={selectedOrderId}
          shippingConfig={orderInquiry.shippingConfig}
          b2bInvoiceConfig={{
            canGenerateB2BInvoice: orderInquiry.canGenerateB2BInvoice,
            cantGenerateB2BInvoiceReason: orderInquiry.cantGenerateB2BInvoiceReason
          }}
        />
        <OrderProductsView inquiryData={orderInquiry} onListMount={onListMount} />
        <Footer inquiryData={orderInquiry} marginScrollOffset={marginScrollOffset} />
      </>
    );
  };

  return (
    <ActiveOrderView.Provider value={orderViewContext}>
      <OrderNoteContext.Provider value={{ open: openOrderModal, setOpen: setOpenOrderModal }}>
        <div className="orderDetailsScreenContainer" ref={containerRef}>
          {getOrderDetailsComponent()}
        </div>
      </OrderNoteContext.Provider>
    </ActiveOrderView.Provider>
  );
};
