import React, { useState, useRef, Fragment, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import Modal from 'react-responsive-modal';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import './CatalogueSettings.scss';
import {
  setCompanyCataloguePdfSettings,
  setCompanyCatalogueProductSettings,
  setCompanyCatalogueProductCountSettings,
  updateCompanyShareTextFirebase,
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  getCompanySettingsFromCache,
  getCatalogueShareTextFromCache,
  attachCatalogueShareTextListener,
  removeCatalogueShareTextListener
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import toastr from 'toastr';
import {
  extractCompanyCataloguePdfSettings,
  extractCompanyCatalogueProductSettings,
  extractCompanyCatalogueProductCountFromViewSettings,
  extractCompanyCatalogueProductCountFromTileSettings,
  extractCompanyShareTextProperty
} from 'qs-helpers/CompanySettings/DataExtracters';
import { getI18N } from 'qs-services/i18N';

export default () => {
  const [cataloguePdf, setCataloguePdf] = useState(
    extractCompanyCataloguePdfSettings(getCompanySettingsFromCache())
  );
  const [showPdfLoader, togglePdfLoader] = useState(false);

  const [catalogueProduct, setCatalogueProduct] = useState(
    extractCompanyCatalogueProductSettings(getCompanySettingsFromCache())
  );
  const [showProductLoader, toggleProductLoader] = useState(false);

  const [removeProductCountFromView, setRemoveProductCountFromView] = useState(
    extractCompanyCatalogueProductCountFromViewSettings(getCompanySettingsFromCache())
  );
  const [showRemoveProductCountFromViewLoader, toggleRemoveProductCountFromViewLoader] = useState(
    false
  );

  const [removeProductCountFromTile, setRemoveProductCountFromTile] = useState(
    extractCompanyCatalogueProductCountFromTileSettings(getCompanySettingsFromCache())
  );
  const [showRemoveProductCountFromTileLoader, toggleRemoveProductCountFromTileLoader] = useState(
    false
  );

  const [templateText, setTemplateText] = useState(() =>
    extractCompanyShareTextProperty(getCatalogueShareTextFromCache())
  );
  const [showTextLoader, toggleTextLoader] = useState(false);

  const [textAreaVal, setTextAreaVal] = useState(templateText);
  const [showTextModal, toggleTextModal] = useState(false);

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  const { t } = getI18N();

  useEffect(() => {
    const catalogueListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_catalogue_information'));
        return;
      }

      const { settings } = data || {};
      setCatalogueProduct(extractCompanyCatalogueProductSettings(settings));
      setCataloguePdf(extractCompanyCataloguePdfSettings(settings));
      setRemoveProductCountFromView(extractCompanyCatalogueProductCountFromViewSettings(settings));
      setRemoveProductCountFromTile(extractCompanyCatalogueProductCountFromTileSettings(settings));
    };

    attachCompanySettingsListener(catalogueListener);
    return () => removeCompanySettingsListener(catalogueListener);
  });

  useEffect(() => {
    const shareTextListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(t('something_went_wrong_while_fetching_the_set_share_text_information'));
        return;
      }

      setTemplateText(extractCompanyShareTextProperty(data));
      setTextAreaVal(extractCompanyShareTextProperty(data));
    };

    attachCatalogueShareTextListener(shareTextListener);
    return () => removeCatalogueShareTextListener(shareTextListener);
  }, [t]);

  const handleRowClick = function(from) {
    return async (_, event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      switch (from) {
        case 'pdfDownload':
          togglePdfLoader(true);
          try {
            await setCompanyCataloguePdfSettings(!cataloguePdf);
          } catch (pdfUpdateError) {
            toastr.error(t('something_went_wrong_while_updating_the_pdf_download_setting'));
          }
          togglePdfLoader(false);
          break;
        case 'productDownload':
          toggleProductLoader(true);
          try {
            await setCompanyCatalogueProductSettings(!catalogueProduct);
          } catch (productError) {
            toastr.error(t('something_went_wrong_while_updating_the_product_download_setting'));
          }
          toggleProductLoader(false);
          break;
        case 'removeProductCountFromView':
          toggleRemoveProductCountFromViewLoader(true);
          try {
            await setCompanyCatalogueProductCountSettings({
              field: 'removeProductCountFromCatalogueView',
              value: !removeProductCountFromView
            });
          } catch (productCountFromViewError) {
            toastr.error(
              t('something_went_wrong_while_updating_the_catalogue_product_count_setting')
            );
          }
          toggleRemoveProductCountFromViewLoader(false);
          break;
        case 'removeProductCountFromTile':
          toggleRemoveProductCountFromTileLoader(true);
          try {
            await setCompanyCatalogueProductCountSettings({
              field: 'removeProductCountFromCatalogueTile',
              value: !removeProductCountFromTile
            });
          } catch (productCountFromTileError) {
            toastr.error(
              `${t(
                'something_went_wrong_while_updating_the_catalogue_product_count_from_homepage_setting'
              )}`
            );
          }
          toggleRemoveProductCountFromTileLoader(false);
          break;

        default:
          break;
      }
    };
  };

  const iconComponent = (switchChecked, from) => (
    <ReactSwitch
      checked={switchChecked}
      onChange={handleRowClick(from)}
      onColor="#515863"
      offColor="#515863"
      onHandleColor="#4DA47A"
      offHandleColor="#929faf"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      height={17}
      width={40}
    />
  );

  const handleTextTemplateChange = function(event) {
    event.preventDefault();
    /*
      Since this handler is being used on both the button and the row,
      prevent this from being called twice
    */
    event.stopPropagation();

    setTextAreaVal(templateText);
    toggleTextModal(!showTextModal);
  };

  const closeTextModal = function() {
    toggleTextModal(false);
  };

  const handleReset = function(event) {
    event.preventDefault();
    setTextAreaVal('');
  };

  const submitText = async function(event) {
    event.preventDefault();
    closeTextModal();
    toggleTextLoader(true);
    try {
      await updateCompanyShareTextFirebase(textAreaVal);
    } catch (textUpdateError) {
      toastr.error(t('something_went_wrong_while_updating_the_share_text'));
    }
    toggleTextLoader(false);
  };

  const renderTextModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeTextModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="catalogue-section-modal catalogue-share-text--title">
        {t('change_share_text')}
      </h3>
      <p className="catalogue-section-modal catalogue-share-text-description">
        {t('type_a_custom_message')}
      </p>
      <textarea
        className="catalogue-section-modal catalogue-text-area"
        value={textAreaVal}
        onChange={event => setTextAreaVal(event.target.value)}
        placeholder={t('type_your_customized_sharing_message')}
      />
      <div className="catalogue-section-modal button-container">
        <button type="reset" className="button-plain button-reset" onClick={handleReset}>
          {t('reset')}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitText}>
          {t('set_share_text')}
        </button>
      </div>
    </Modal>
  );

  let catalogueComponent;
  if (typeof cataloguePdf === 'boolean' && typeof catalogueProduct === 'boolean') {
    catalogueComponent = (
      <Fragment>
        <SettingsDataRow
          icon={
            <button className="text-template-share" onClick={handleTextTemplateChange}>
              {t('change')}
            </button>
          }
          loader={showTextLoader}
          title={t('sharing_text_template')}
          onSectionClick={handleTextTemplateChange}
        >
          {typeof templateText === 'string' && templateText.trim().length > 0 && (
            <span className="text-template-share-container">{templateText.trim()}</span>
          )}
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('pdfDownload')}
          icon={iconComponent(cataloguePdf, 'pdfDownload')}
          loader={showPdfLoader}
          title={t('enable_pdf_download')}
        >
          <span>{t('allow_your_customers_to_download_the_pdf_of_your_catalogue')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('productDownload')}
          icon={iconComponent(catalogueProduct, 'productDownload')}
          loader={showProductLoader}
          title={t('enable_product_download')}
        >
          <span>{t('allow_customers_to_download_your_product_images')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('removeProductCountFromTile')}
          icon={iconComponent(removeProductCountFromTile, 'removeProductCountFromTile')}
          loader={showRemoveProductCountFromTileLoader}
          title={t('remove_product_count_from_homepage')}
        >
          <span>{t('hide_the_count_of_the_product_homepage')}</span>
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('removeProductCountFromView')}
          icon={iconComponent(removeProductCountFromView, 'removeProductCountFromView')}
          loader={showRemoveProductCountFromViewLoader}
          title={t('remove_catalogue_product_count')}
        >
          <span>{t('hide_the_count_of_the_products_shown_below_catalogue')}</span>
        </SettingsDataRow>
        {showTextModal && renderTextModal()}
      </Fragment>
    );
  } else {
    catalogueComponent = (
      <div className="catalogue-section-loader">
        <Loader size="large" />
      </div>
    );
  }

  return <div className="catalogue-section">{catalogueComponent}</div>;
};
