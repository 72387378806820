import React from 'react';
import { DialogContent } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'qs-assets/Media/ic_arrow_right.svg';
import { ReactComponent as LockWhite } from 'qs-assets/Media/icon_lock_white.svg';
import { DialogGradientTheme, PrimaryButton } from '../GradientThemeDialog';
import Loader from '../Loader';
import { openWhatsApp } from 'qs-helpers';
import { CHAT_NUMBER } from 'qs-helpers/constants';
import {
  fetchFeaturePlanHandler,
  getFeaturePlanData,
  handleFeaturePlanCleanup
} from 'qs-helpers/FeaturePlan/DataQueryHelper';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

export default ({ onClose, currentPlanFeature }) => {
  const {
    id,
    isAllowed: { allowedFor }
  } = currentPlanFeature;
  const { t } = getI18N();

  const [{ data, error, loading }] = useMakeQuery({
    actionHandler: fetchFeaturePlanHandler,
    cleanupHandler: handleFeaturePlanCleanup,
    cachedDataHandler: getFeaturePlanData
  });

  const upgradePlan = plan => {
    const text = t('i_want_to_upgrade_my_plan_to', { plan });
    openWhatsApp({ contact: CHAT_NUMBER, text });
    onClose();
  };

  const getFeatureImage = () => {
    if (!data || !data.features) {
      return null;
    }
    const feature = data.features.find(feature => feature.id === id);
    if (feature && feature.bigIcon) {
      return <img className="featureImage" src={feature.bigIcon} alt="" />;
    }
  };

  const getFeatureContent = () => {
    if (!data || !data.features) {
      return null;
    }
    let feature = data.features.find(feature => feature.id === id);
    if (!feature) {
      feature = {};
    }
    if (!feature.title) {
      feature.title = t(id);
    }
    if (feature.title || feature.desc) {
      return (
        <>
          {feature.title && <div className="featureTitle">{feature.title}</div>}
          {feature.desc && <div className="featureDescription">{feature.desc}</div>}
        </>
      );
    }
  };

  return (
    <DialogGradientTheme open={true} maxWidth={'xs'} onClose={onClose}>
      <DialogContent className="featureModal">
        <div className="featureModalContent">
          {loading || error ? (
            <div className="loading">
              <Loader size="large" />
            </div>
          ) : (
            <>
              <div className="featureHeader">
                {getFeatureImage()}
                <div className="helpIcon">
                  <LockWhite className="icon" />
                </div>
              </div>
              {getFeatureContent()}
              {!!allowedFor && allowedFor.length > 0 && (
                <div className="featureUpgradeOptions">
                  {allowedFor.map((plan, index) => {
                    return (
                      <PrimaryButton
                        key={index}
                        className="upgradeButton"
                        onClick={() => upgradePlan(plan)}
                      >
                        {t('upgrade_to_plan', { plan })}
                        <ArrowRight className="arrowRight" />
                      </PrimaryButton>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </DialogGradientTheme>
  );
};
