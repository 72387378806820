import CacheRequest from 'qs-data-manager/CacheRequest';
import cloneDeep from 'lodash.clonedeep';
import Api from '../Api';
import eventbus from 'eventing-bus';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import { upsertCatalogueRowInNative } from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import * as Sentry from '@sentry/browser';
import { reportError } from './ErrorReporting';
import { PRODUCT_VARIANT_INFO } from 'qs-api/Variants/ApiCacheConnector';
import { registerCleanupHandler } from './ClearSavedData';
import { getImageUrlFromPictureId } from '.';
import { getUniqueDeviceId } from './DeviceIdGenerator';

export const UPLOAD_PRODUCT_PICURES_HEADER = {
  eventbusKey: id => `PRODUCT_HEADER_IMAGE_UPLOAD${id}`,
  meta: {} // { productId: { totalImages, uploaded } }
};

export const UPLOAD_PRODUCT_HEADER = {
  eventbusKey: id => `PRODUCT_HEADER_IMAGE_UPLOAD${id}`,
  meta: {}, // { catalogueId: { totalImages, uploaded } }
  uploaded: {}
};

export const UPLOAD_VARIANT_HEADER = {
  eventbusKey: id => `UPLOAD_VARIANT_HEADER${id}`,
  meta: {}, // { variantId: { totalImages, uploaded } },
  optionVariantsMap: new Map()
};

export const IMAGE_UPLOAD_HELPER = {
  PRODUCT_UPLOAD: {
    key: 'PRODUCT_UPLOAD'
  },
  PRODUCT_EXTRA_PICTURE_UPLOAD: {
    key: 'PRODUCT_EXTRA_PICTURE_UPLOAD'
  },
  VARIANT_PICTURE_UPLOAD: {
    key: 'VARIANT_PICTURE_UPLOAD'
  }
};

const upsertImageMetaForHeader = ({ uploadHeader, mappingId, totalImages, uploaded = 0 }) => {
  const existingHeaderMeta = uploadHeader.meta[mappingId];
  if (existingHeaderMeta) {
    const updatedTotal = existingHeaderMeta.totalImages + totalImages;
    existingHeaderMeta.totalImages = updatedTotal;
    return { uploaded: existingHeaderMeta.uploaded, totalImages: updatedTotal };
  }

  uploadHeader.meta[mappingId] = {
    uploaded,
    totalImages
  };

  return { uploaded, totalImages };
};

const clearImageMetaForHeader = ({ uploadHeader }) => {
  for (const mappingId in uploadHeader.meta) {
    if (uploadHeader.meta.hasOwnProperty(mappingId)) {
      delete uploadHeader.meta[mappingId];
    }
  }
};

export const clearCatalogueImageMeta = () => {
  clearImageMetaForHeader({ uploadHeader: UPLOAD_PRODUCT_HEADER });
};

export const clearProductImageMeta = () => {
  clearImageMetaForHeader({ uploadHeader: UPLOAD_PRODUCT_PICURES_HEADER });
};

export const clearVariantImageMeta = () => {
  clearImageMetaForHeader({ uploadHeader: UPLOAD_VARIANT_HEADER });
};

const calcRemainingAndPercent = ({ uploaded, totalImages }) => {
  let percent = Math.round((uploaded / totalImages) * 100);
  percent = percent > 0 ? percent : 0;
  const remaining = totalImages - uploaded;
  return { percent, remaining };
};

export const changeProgressBarHeadersForMeta = ({
  uploadHeader,
  mappingId,
  uploadedImages = 1
}) => {
  if (uploadHeader.meta[mappingId]) {
    let meta = uploadHeader.meta[mappingId];
    const prevUploaded = meta.uploaded;

    uploadHeader.meta[mappingId] = {
      ...uploadHeader.meta[mappingId],
      uploaded: prevUploaded + uploadedImages
    };

    meta = uploadHeader.meta[mappingId];

    const { percent, remaining } = calcRemainingAndPercent(meta);

    const key = uploadHeader.eventbusKey(mappingId);
    let shouldShow = true;

    if (remaining <= 0) {
      shouldShow = false;
      delete uploadHeader.meta[mappingId];
    }

    eventbus.publish(key, {
      shouldShow,
      percent: percent,
      remaining
    });
  }
};

const trackImagesUploadedToCatalogue = ({ pictureId, catalogueId }) => {
  if (!UPLOAD_PRODUCT_HEADER.uploaded[catalogueId]) {
    UPLOAD_PRODUCT_HEADER.uploaded[catalogueId] = {};
  }

  UPLOAD_PRODUCT_HEADER.uploaded[catalogueId][pictureId] = true;
};

export const processImageUpload = async ({
  pictureId,
  isPrepared,
  defaultImageErrored,
  catalogueId
}) => {
  const cataloguePictures = UPLOAD_PRODUCT_HEADER.uploaded[catalogueId];
  if ((isPrepared || defaultImageErrored) && cataloguePictures && cataloguePictures[pictureId]) {
    changeProgressBarHeadersForMeta({
      uploadHeader: UPLOAD_PRODUCT_HEADER,
      mappingId: catalogueId,
      uploadedImages: 1
    });
    delete cataloguePictures[pictureId];

    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);
    let catalogueDataUpdated = false;

    const newPicturesMeta = (catalogueRowCache.picturesMeta || []).map(pictureState => {
      if (pictureState && pictureState.pictureId === pictureId) {
        catalogueDataUpdated = true;
        return {
          pictureId,
          url: getImageUrlFromPictureId({ size: 'FULL', pictureId }),
          prepared: isPrepared,
          error: defaultImageErrored
        };
      }
      return pictureState;
    });

    if (catalogueDataUpdated) {
      const newCatalogueRowCache = {
        ...catalogueRowCache,
        picturesMeta: newPicturesMeta
      };

      CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);

      try {
        await upsertCatalogueRowInNative({ [catalogueId]: newCatalogueRowCache });
      } catch (upsertCatalogueError) {
        Sentry.captureException(upsertCatalogueError);
      }
    }

    const pictureIdsLeft = Object.keys(cataloguePictures);
    if (!pictureIdsLeft.length) {
      delete UPLOAD_PRODUCT_HEADER.uploaded[catalogueId];
    }
  }
};

const updateInfoCache = ({ isPrepared, error, pictureId }, { cacheKey, possibleDefaultImage }) => {
  const basicInfoCache = CacheRequest.getCacheForKey(cacheKey);
  if (!basicInfoCache) {
    return;
  }

  const newBasicInfoCache = cloneDeep(basicInfoCache);
  //Update the prepared state of the picture id
  if (!newBasicInfoCache.pictures || !newBasicInfoCache.pictures[pictureId]) {
    return;
  }

  const existingPictureData = newBasicInfoCache.pictures[pictureId];
  let { url } = existingPictureData;
  if (!url) {
    url = getImageUrlFromPictureId({ pictureId });
  }

  newBasicInfoCache.pictures[pictureId] = {
    ...existingPictureData,
    id: pictureId,
    prepared: isPrepared,
    url
  };

  //If the default picture id is not present, then set it as this picture
  if (!newBasicInfoCache.default_picture_id && possibleDefaultImage === pictureId) {
    newBasicInfoCache.default_picture_id = pictureId;
    newBasicInfoCache.pictureUrl = getImageUrlFromPictureId({ pictureId });
  }

  CacheRequest.setCacheForKey(cacheKey, newBasicInfoCache);
};

// Handles side effect of when extra picture are done uploading to a single product
// TODO this entrie thing will be replaced by real time updates
const pictureUploadToProductDone = async data => {
  const { productId } = data;
  updateInfoCache(data, { cacheKey: `${connector.BASIC_INFO.cacheKey}${productId}` });
  changeProgressBarHeadersForMeta({
    uploadHeader: UPLOAD_PRODUCT_PICURES_HEADER,
    mappingId: productId,
    uploadedImages: 1
  });
};

// Handles side effect of when extra picture are done uploading to a single product
// TODO this entrie thing will be replaced by real time updates
const pictureUploadToVariantDone = async data => {
  const { productId, extraData: { optionId } = {} } = data;
  let { affectedVariantsIds, possibleDefaultImage, totalPictureCount } =
    UPLOAD_VARIANT_HEADER.optionVariantsMap.get(optionId) || {};

  if (!Array.isArray(affectedVariantsIds)) {
    affectedVariantsIds = [productId];
  }

  affectedVariantsIds.forEach(variantId => {
    updateInfoCache(data, {
      cacheKey: `${PRODUCT_VARIANT_INFO.cacheKey}${variantId}`,
      possibleDefaultImage
    });
  });

  changeProgressBarHeadersForMeta({
    uploadHeader: UPLOAD_VARIANT_HEADER,
    mappingId: productId,
    uploadedImages: 1
  });

  if (typeof totalPictureCount !== 'number') {
    return;
  }

  totalPictureCount -= 1;
  //All pictures that were uploaded for this option have been processed
  //remove this entry from the map
  if (totalPictureCount <= 0) {
    UPLOAD_VARIANT_HEADER.optionVariantsMap.delete(optionId);
    return;
  }

  UPLOAD_VARIANT_HEADER.optionVariantsMap.set(optionId, {
    affectedVariantsIds,
    possibleDefaultImage,
    totalPictureCount
  });
};

const handleUploadFailure = async ({ pictureId }) => {
  try {
    await Api.updateProductPicturesError([pictureId]);
  } catch (error) {
    reportError(error);
  }
};

export const onImageUploadDone = async data => {
  if (data) {
    if (data.error) {
      handleUploadFailure(data);
    }

    if (data.extraData.calledFrom === IMAGE_UPLOAD_HELPER.PRODUCT_UPLOAD.key) {
      /*
        Add the processed images to a temporary map; this map will be used to determine
        whether a picture from real time update must be processed or not.
      */
      trackImagesUploadedToCatalogue(data);
      return;
    }

    //TODO the below handling must be made similar to the handling for upload to catalogue
    if (data.extraData.calledFrom === IMAGE_UPLOAD_HELPER.PRODUCT_EXTRA_PICTURE_UPLOAD.key) {
      pictureUploadToProductDone(data);
      return;
    }

    //TODO the below handling must be made similar to the handling for upload to catalogue
    if (data.extraData.calledFrom === IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key) {
      pictureUploadToVariantDone(data);
      return;
    }
  }
};

const progresBarToggleForHeader = ({ uploadHeader, mappingId, totalImages, uploaded }) => {
  const meta = upsertImageMetaForHeader({
    uploadHeader,
    mappingId,
    totalImages,
    uploaded
  });

  const { percent, remaining } = calcRemainingAndPercent(meta);
  let shouldShow = true;
  if (remaining <= 0) {
    shouldShow = false;
    delete uploadHeader.meta[mappingId];
  }

  const key = uploadHeader.eventbusKey(mappingId);
  eventbus.publish(key, {
    shouldShow,
    percent,
    remaining
  });
};

export const toggleProductHeaderProgressBar = ({ catalogueId, totalImages, uploaded }) => {
  progresBarToggleForHeader({
    uploadHeader: UPLOAD_PRODUCT_HEADER,
    mappingId: catalogueId,
    totalImages,
    uploaded
  });
};

export const toggleProductPicturesHeader = ({ productId, totalImages, uploaded }) => {
  progresBarToggleForHeader({
    uploadHeader: UPLOAD_PRODUCT_PICURES_HEADER,
    mappingId: productId,
    totalImages,
    uploaded
  });
};

export const toggleVariantPicturesHeader = ({ variantId, totalImages, uploaded }) => {
  progresBarToggleForHeader({
    uploadHeader: UPLOAD_VARIANT_HEADER,
    mappingId: variantId,
    totalImages,
    uploaded
  });
};

/**
 * This method will determine whether images are currently being uploaded or not
 * @returns true if images are uploading; false otherwise
 */
export const isImageUploadInProgress = () => {
  /*
    Whenever an image is processed successfully, the progress bar is updated.
    If all images have completed, whether success or fail, the entry from the meta
    is removed. similar handling is done for both catalogue and product header.
    Hence if even one of the headers has an entry then signify that image upload
    is in progress.
  */
  const productIdsUpload = Object.keys(UPLOAD_PRODUCT_PICURES_HEADER.meta || {});
  const catalogueIdsUpload = Object.keys(UPLOAD_PRODUCT_HEADER.meta || {});
  const variantIdsUpload = Object.keys(UPLOAD_VARIANT_HEADER.meta || {});
  return productIdsUpload.length || catalogueIdsUpload.length || variantIdsUpload.length;
};

export const getPicturesUploadingMetaForHeader = ({ uploadHeader, mappingId }) => {
  if (!uploadHeader.meta[mappingId]) {
    return {
      shouldShow: false,
      percent: 0,
      remaining: 0
    };
  }

  const meta = uploadHeader.meta[mappingId];
  let percent = Math.round((meta.uploaded / meta.totalImages) * 100);
  percent = percent > 0 ? percent : 0;
  const remaining = meta.totalImages - meta.uploaded;

  return {
    shouldShow: true,
    percent,
    remaining
  };
};

export const failPendingImagesForThisDevice = async () => {
  const uuid = getUniqueDeviceId();
  try {
    await Api.markPendingImagesToError(uuid);
  } catch (error) {
    reportError(error);
  }
};

const clearSavedPictureData = () => {
  UPLOAD_PRODUCT_PICURES_HEADER.meta = {};
  UPLOAD_PRODUCT_HEADER.meta = {};
  UPLOAD_PRODUCT_HEADER.uploaded = {};
  UPLOAD_VARIANT_HEADER.meta = {};
  UPLOAD_VARIANT_HEADER.optionVariantsMap.clear();
};

registerCleanupHandler(clearSavedPictureData);
