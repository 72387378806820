import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useMemo,
  useCallback,
  useRef
} from 'react';
import Loader from 'qs-components/Common/Loader';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CurrentDefaultImageContext } from '../context';
import { convertImageUrlSize } from 'qs-helpers';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import SetAsDefaultAction from './SetAsDefaultAction';
import { ReactComponent as CloseSVG } from 'qs-assets/Media/close.svg';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import { IMAGE_ERROR, IMAGE_LOADER, IMAGE_HIDE_LOADER } from 'qs-helpers/PicturesProcessingHelper';
import ImageGalleryView from '../../../Common/ImageGalleryView';
import VideoPlayer from '../../../VideoPlayer/index';
import DeleteMediaAction from './DeleteMediaAction';
import ImageUpload from '../Common/ImageUpload';
import { hslVideoUrl } from '../../../../Services/VideoUpload/UploadVideos/Api';
import eventbus from 'eventing-bus';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

const useStyles = makeStyles({
  button: {
    color: '#FFF',
    fontSize: 20,
    background: 'transperent',
    padding: 0,
    height: 32,
    width: 32,
    alignSelf: 'flex-end',
    flex: '0 0 auto'
  }
});

export default ({ containerRef, activeProductId } = {}) => {
  const [height, setHeight] = useState(null);
  const [open, setOpen] = useState(false);
  const renderedImageContext = useContext(CurrentDefaultImageContext);
  const [rearrangingInProgress, setRearrangingInProgress] = useState(false);
  const pictureState = renderedImageContext.currentImageMeta.currentImageData;
  const productMediaRef = useRef();

  const { t } = getI18N();

  const classes = useStyles();

  const imageUrl = useMemo(() => convertImageUrlSize({ size: 'FULL', url: pictureState.url }), [
    pictureState.url
  ]);

  const onProductMediaMount = ref => {
    productMediaRef.current = ref;
  };

  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();

  useEffect(() => {
    updateTimeoutRefForPictures([pictureState]);
  }, [pictureState.id, updateTimeoutRefForPictures, pictureState]);

  useEffect(() => {
    if (containerRef) {
      setHeight(containerRef.offsetHeight / 2 - 75);
    }
  }, [containerRef]);

  useEffect(() => {
    const removeEventbus = eventbus.on('REARRANGING_PICTURES_IN_PROGRESS', value => {
      setRearrangingInProgress(value);
    });

    return () => removeEventbus();
  }, []);

  const toggleImageModal = useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);

  if (typeof height !== 'number') {
    return null;
  }

  const { state = IMAGE_LOADER, retryCount = 0 } = picturesLoadState[pictureState.id] || {};
  if ((pictureState.error && !pictureState.prepared) || state === IMAGE_ERROR) {
    return (
      <div className="productSingleImage">
        <div className="ProductImageErrorContainer" style={{ height }}>
          <ErrorIcon className={'errorIcon'} />
        </div>
      </div>
    );
  }

  const renderImageActions = () => {
    if (renderedImageContext.currentImageMeta.currentImageDefault || rearrangingInProgress) {
      return null;
    }
    if (
      !pictureState ||
      !pictureState.id ||
      !picturesLoadState[pictureState.id] ||
      !picturesLoadState[pictureState.id].state ||
      picturesLoadState[pictureState.id].state === 'LOADER' ||
      pictureState.hasOwnProperty('processed')
    ) {
      return <DeleteMediaAction activeProductId={activeProductId} />;
    }
    return (
      <>
        <SetAsDefaultAction currentImageData={pictureState} pictureUrl={imageUrl} />
        <DeleteMediaAction activeProductId={activeProductId} />
      </>
    );
  };

  return (
    <Fragment>
      <div
        className="productSingleImage"
        style={{ height }}
        onClick={
          pictureState.hasOwnProperty('processed')
            ? () => {}
            : pictureState.url
            ? toggleImageModal
            : () => {}
        }
      >
        {pictureState.hasOwnProperty('processed') && pictureState.prepared ? (
          <div style={{ height, display: 'flex' }}>
            <VideoPlayer
              className="product-video"
              onVideoMount={onProductMediaMount}
              videoId={pictureState.id}
              videoUrl={hslVideoUrl(pictureState.id)}
              seekTo
              withAudio
              controls
            />
          </div>
        ) : pictureState.prepared ? (
          <>
            <div
              key={`${pictureState.id}-blurred-${retryCount}`}
              className="ProductImage blurredImage"
              style={{
                height: height,
                backgroundImage: `url(${imageUrl})`
              }}
            />
            <div
              key={`${pictureState.id}-nonblurred-${retryCount}`}
              className="ProductImage nonBlurredImage"
              style={{
                height,
                backgroundImage: `url(${imageUrl})`
              }}
            />
            <img
              key={`${pictureState.id}-${retryCount}`}
              onError={handleImageError(pictureState.id, IMAGE_ERROR)}
              onLoad={handleImageLoad(pictureState.id, IMAGE_HIDE_LOADER)}
              src={imageUrl}
              className={'ProductImage previewImage'}
              alt={''}
            />
          </>
        ) : pictureState.id === null || pictureState.url === null ? (
          <div className="imageUploadContainer" style={{ height }}>
            <ImageUpload />
            <span className="uploadText">{t('upload_media')}</span>
          </div>
        ) : (
          state === IMAGE_LOADER && (
            <div
              style={{ height }}
              className={`ProductImageLoaderContainer ${
                pictureState.prepared ? '' : 'imageNotPrepared'
              }`}
            >
              <Loader size={'large'} />
            </div>
          )
        )}
      </div>
      <div className="imageActions">{renderImageActions()}</div>
      {open && (
        <ImageGalleryView
          onClose={toggleImageModal}
          picturesData={[
            {
              ...pictureState,
              url: imageUrl
            }
          ]}
        >
          <ButtonBase
            variant="contained"
            className={classes.button}
            onClick={toggleImageModal}
            size="small"
          >
            <CloseSVG className="productSingleImageDlg closeSvg" />
          </ButtonBase>
        </ImageGalleryView>
      )}
    </Fragment>
  );
};
