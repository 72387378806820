import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getAppVersion } from 'qs-helpers';
import './SettingsLeftPanel.scss';
import CouponIcon from 'qs-assets/Media/ic_coupon_white.png';
import SettingsLeftCompanyView from '../CompanyProfile/SettingsLeftCompanyView/SettingsLeftCompanyView';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { SETTINGS_COMPONENT_ID_MAP } from 'qs-helpers/CompanySettings/constants';
import {
  getConfigFromCache,
  attachConfigsListener,
  removeConfigsListener
} from 'qs-data-manager/Config';
import PlansBillingRow from './PlansBillingRow';
import PaymentGatewayRow from './PaymentGatewayRow/PaymentGatewayRow';
import ShippingCostRow from './ShippingCostRow';
import CustomThemesRow from './CustomThemesRow';
import CustomProductGridTileRow from './CustomProductGridTileRow';
import CustomProductListTileRow from './CustomProductListTileRow';
import CustomProductMagazineTileRow from './CustomProductMagazineTileRow';
import CustomCatalogueGridTileRow from './CustomCatalogueGridTileRow';
import OtherPagesRow from './OtherPagesRow';
import CustomPagesRow from './CustomPagesRow';
import CustomFooterRow from './CustomFooterRow';
import CustomFontRow from './CustomFontRow';
import CreateMenuRow from './CreateMenuRow';
import ReferralRow from './ReferralRow';
import FacebookPixelRow from './FacebookPixelRow';
import GoogleAnalyticsRow from './GoogleAnalyticsRow';
import GoogleSearchConsoleRow from './GoogleSearchConsoleRow';
import LinkSettingsRow from '../../Common/LinkSettingsRow/LinkSettingsRow';
import ButtonSettingsRow from '../../Common/ButtonSettingsRow/ButtonSettingsRow';
import ProductDemoRow from './ProductDemoRow/ProductDemoRow';
import JewelleryPriceDisplayRow from './JewelleryPriceDisplayRow';
import CustomWebhooksRow from './CustomWebhooksRow';
import LogoutUser from './LogoutUser';
import ReportsImg from 'qs-assets/Media/ic_reports.png';
import { ReactComponent as ApiIcon } from 'qs-assets/Media/icon_api.svg';
import TaxWhiteIcon from '../../../Common/TaxWhiteIcon';
import CustomFieldsIcon from '../../../Common/CustomFieldsIcon';
import ProductOptionsIcon from '../../../Common/ProductOptionIcon';
import MetalRatePageIcon from '../../../Common/MetalRatePageIcon';
import StopHandIcon from '../../../../Assets/Media/stop-hand-circle.png';
import { PAID_FEATURE } from '../../../Common/FeatureModal/helper';
import { getI18N } from 'qs-services/i18N';

export default ({ handleSettingsItemClick, selectedId }) => {
  useEffect(() => {
    const handleScroll = () => {};
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom
    };
  };

  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
  const basicRef = useRef(null);
  const contentRef = useRef(null);
  const marketingRef = useRef(null);
  const moreRef = useRef(null);
  const settingsRef = useRef(null);
  const isManageApiFeatureAllowed = PAID_FEATURE.PUBLIC_KEY.isAllowed || false;

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition =
        document.querySelector('.configureSettingsList').scrollTop + headerHeight;

      const selected = settingsDataOptions.find(({ sectionRef }) => {
        const ele = sectionRef.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return null;
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    const element = document.querySelector('.configureSettingsList');
    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  });

  const scrollTo = ele => {
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const { t } = getI18N();

  const createSettingsData = useCallback(
    linkConfigs => {
      return [
        {
          section: t('basic'),
          sectionRef: basicRef,
          sectionComp: [
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME,
              componentType: 'COMPANY_NAME_RENDERER'
            },
            // {
            //   componentId: SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING,
            //   componentType: 'PLANS_BILLING_RENDERER'
            // },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS,
                description: t('connect_and_manage_custom_domain'),
                imageSrc: 'https://web.quicksell.co/static/media/icon_website.svg',
                title: t('domain')
              }
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS,
              componentType: 'PAYMENT_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS,
              componentType: 'SHIPPING_COST_SETTINGS_RENDERER'
            }
          ]
        },
        {
          section: t('content_and_layout'),
          sectionRef: contentRef,
          sectionComp: [
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS,
              componentType: 'CUSTOM_THEMES_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE,
              componentType: 'CUSTOM_PRODUCT_GRID_TILE_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE,
              componentType: 'CUSTOM_PRODUCT_LIST_TILE_RENDERER'
            },
            // {
            //   componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE,
            //   componentType: 'CUSTOM_PRODUCT_MAGAZINE_TILE_RENDERER'
            // },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE,
              componentType: 'CUSTOM_CATALOGUE_GRID_TILE_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS,
              componentType: 'OTHER_PAGES_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS,
              componentType: 'CUSTOM_PAGES_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS,
              componentType: 'CUSTOM_FOOTER_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS,
              componentType: 'CUSTOM_FONT_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS,
              componentType: 'CREATE_MENU_SETTINGS_RENDERER'
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS,
                description: t('add_and_manage_variants_to_your_products'),
                Icon: ProductOptionsIcon,
                title: t('product_options')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS,
                description: t('add_and_manage_metal_rates'),
                Icon: MetalRatePageIcon,
                title: t('metal_rates')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS,
                description: t('add_and_manage_custom_fields'),
                Icon: CustomFieldsIcon,
                title: t('custom_fields')
              }
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS,
              componentType: 'WEBHOOKS_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY,
              componentType: 'JEWELLERY_PRICE_DISPLAY_RENDERER'
            }
          ]
        },
        {
          section: t('settings'),
          sectionRef: settingsRef,
          sectionComp: [
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS,
                description: t('apply_or_add_taxes_for_your_products'),
                Icon: TaxWhiteIcon,
                title: t('taxes')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS,
                description: t('manage_your_inventory_settings_at_a_global_level'),
                imageSrc: 'https://web.quicksell.co/static/media/ic_inventory.png',
                title: t('inventory_settings')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS,
                description: t('use_quicksell_in_your_language'),
                imageSrc: 'https://web.quicksell.co/static/media/ic_choose_app_language.png',
                title: t('language_settings')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS,
                description: t('manage_your_catalogue_settings'),
                imageSrc: 'https://web.quicksell.co/static/media/ic_catalogue.png',
                title: t('catalogue_settings')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS,
                description: t('manage_your_checkout_settings'),
                imageSrc: 'https://web.quicksell.co/static/media/ic_checkout.png',
                title: t('checkout_settings')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS,
                description: t('select_your_company_wide_product_type'),
                imageSrc: 'https://web.quicksell.co/static/media/ic_catalogue.png',
                title: t('product_type_settings')
              }
            },
            ...(isManageApiFeatureAllowed
              ? [
                  {
                    componentType: 'BUTTON_RENDERER',
                    rowData: {
                      componentId: SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY,
                      description: t('integrate_quicksell_with_3rd_party_software'),
                      Icon: ApiIcon,
                      title: t('developer_api')
                    }
                  }
                ]
              : []),
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS,
                description: t('manage_your_privacy_settings'),
                title: t('privacy_settings'),
                imageSrc: StopHandIcon
              }
            }
          ]
        },
        {
          section: t('marketing'),
          sectionRef: marketingRef,
          sectionComp: [
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE,
                description: t('create_coupons_for_your_customers'),
                imageSrc: CouponIcon,
                title: t('coupons')
              }
            },
            {
              componentType: 'BUTTON_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS,
                description: t('access_your_sales_reports_and_metrics'),
                imageSrc: ReportsImg,
                title: t('reports')
              }
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS,
              componentType: 'FACEBOOK_PIXEL_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS,
              componentType: 'GOOGLE_ANALYTICS_SETTINGS_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS,
              componentType: 'GOOGLE_SEARCH_CONSOLE_RENDERER'
            }
          ]
        },
        {
          section: t('more'),
          sectionRef: moreRef,
          sectionComp: [
            {
              componentType: 'LINK_RENDERER',
              rowData: {
                title: t('become_a_quicksell_partner'),
                componentId: SETTINGS_COMPONENT_ID_MAP.QUICKSELL_PARTNER,
                description: t('sell_quicksell_in_your_local_region', { percent: '15' }),
                linkUrl: linkConfigs.partnerLink,
                imageSrc: 'https://web.quicksell.co/static/media/ic_partner.png'
              }
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.WEBINAR,
              componentType: 'PRODUCT_DEMO_RENDERER'
            },
            {
              componentId: SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS,
              componentType: 'REFERRAL_CODE_SETTINGS_RENDERER'
            },
            {
              componentType: 'LINK_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.NEW_UPDATES,
                description: t('learn_about_our_latest_updates'),
                linkUrl: linkConfigs.newUpdates,
                imageSrc: 'https://web.quicksell.co/static/media/ic_new_updates.png',
                title: t('new_updates'),
                componentEvent: {
                  eventName: 'new_updates_clicked',
                  props: { from: 'SettingsScreen' }
                }
              }
            },
            {
              componentType: 'LINK_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.ABOUT_US,
                description: t('more_information_about_quicksell_app'),
                linkUrl: 'https://quicksell.co/about.html',
                imageSrc: 'https://web.quicksell.co/static/media/ic_about_us.png',
                title: t('about_us')
              }
            },
            {
              componentType: 'LINK_RENDERER',
              rowData: {
                componentId: SETTINGS_COMPONENT_ID_MAP.HELP_TRANSLATE,
                description: t('help_us_translate_quicksell_app'),
                linkUrl: linkConfigs.translationInviteLink,
                imageSrc: 'https://web.quicksell.co/static/media/ic_translation.png',
                title: t('help_us_translate'),
                componentEvent: {
                  eventName: 'help_us_translate_clicked',
                  props: { from: 'SettingsScreen' }
                }
              }
            }
            // {
            //   componentType: 'BUTTON_RENDERER',
            //   rowData: {
            //     componentId: SETTINGS_COMPONENT_ID_MAP.SUGGESTION_FEEDBACKS,
            //     description: t('let_us_know_how_we_can_improve'),
            //     imageSrc: 'https://web.quicksell.co/static/media/ic_feedback.png',
            //     title: t('suggestions_and_feedback')
            //   }
            // }
          ]
        }
      ];
    },
    [isManageApiFeatureAllowed, t]
  );

  const [settingsDataOptions, setSettingsDataOptions] = useState(() =>
    createSettingsData(getConfigFromCache() || {})
  );

  // Attach config listeners. These will be used to fetch the urls for the links.
  useEffect(() => {
    const configListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing || err) {
        return;
      }

      setSettingsDataOptions(createSettingsData(data));
    };

    attachConfigsListener(configListener);

    return () => removeConfigsListener(configListener);
  }, [createSettingsData]);

  const getClassForContainer = function(componentId) {
    if (selectedId === SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS) {
      return `settings-row-container ${
        componentId === SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS ? 'selected-setting' : ''
      }`;
    }
    return `settings-row-container ${selectedId === componentId ? 'selected-setting' : ''}`;
  };

  return (
    <div className="settingsList">
      <div className="configureList">
        <div className="configureTitle">{t('configure')}</div>
        <div className="header" ref={headerRef}>
          {settingsDataOptions.map((section, key) => {
            return (
              <button
                key={key}
                type="button"
                style={section.sectionStyle}
                className={`${visibleSection === section.section ? 'selected' : 'configureMenu'}`}
                onClick={() => {
                  scrollTo(section.sectionRef.current);
                }}
              >
                {section.section}
              </button>
            );
          })}
        </div>
      </div>
      <div className="configureSettingsList">
        {settingsDataOptions.map((settingsOptionData, key) => {
          return (
            <div key={key} className="section" ref={settingsOptionData.sectionRef}>
              <div className="sectionTitle">{settingsOptionData.section}</div>

              {settingsOptionData.sectionComp.map((newSettingsData, key) => {
                switch (newSettingsData.componentType) {
                  case 'COMPANY_NAME_RENDERER':
                    return (
                      <SettingsLeftCompanyView
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'PLANS_BILLING_RENDERER':
                    return (
                      <PlansBillingRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'PAYMENT_SETTINGS_RENDERER':
                    return (
                      <PaymentGatewayRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'SHIPPING_COST_SETTINGS_RENDERER':
                    return (
                      <ShippingCostRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_THEMES_SETTINGS_RENDERER':
                    return (
                      <CustomThemesRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_PRODUCT_GRID_TILE_RENDERER':
                    return (
                      <CustomProductGridTileRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_PRODUCT_LIST_TILE_RENDERER':
                    return (
                      <CustomProductListTileRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_PRODUCT_MAGAZINE_TILE_RENDERER':
                    return (
                      <CustomProductMagazineTileRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_CATALOGUE_GRID_TILE_RENDERER':
                    return (
                      <CustomCatalogueGridTileRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'OTHER_PAGES_SETTINGS_RENDERER':
                    return (
                      <OtherPagesRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_PAGES_SETTINGS_RENDERER':
                    return (
                      <CustomPagesRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_FOOTER_SETTINGS_RENDERER':
                    return (
                      <CustomFooterRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CUSTOM_FONT_SETTINGS_RENDERER':
                    return (
                      <CustomFontRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'CREATE_MENU_SETTINGS_RENDERER':
                    return (
                      <CreateMenuRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'BUTTON_RENDERER':
                    return (
                      <ButtonSettingsRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        buttonRowData={newSettingsData.rowData}
                        classForContainer={getClassForContainer(
                          newSettingsData.rowData.componentId
                        )}
                      />
                    );
                  case 'JEWELLERY_PRICE_DISPLAY_RENDERER':
                    return (
                      <JewelleryPriceDisplayRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'WEBHOOKS_SETTINGS_RENDERER':
                    return (
                      <CustomWebhooksRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'REFERRAL_CODE_SETTINGS_RENDERER':
                    return (
                      <ReferralRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'FACEBOOK_PIXEL_SETTINGS_RENDERER':
                    return (
                      <FacebookPixelRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'GOOGLE_ANALYTICS_SETTINGS_RENDERER':
                    return (
                      <GoogleAnalyticsRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'GOOGLE_SEARCH_CONSOLE_RENDERER':
                    return (
                      <GoogleSearchConsoleRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'PRODUCT_DEMO_RENDERER':
                    return (
                      <ProductDemoRow
                        key={key}
                        onSelected={handleSettingsItemClick}
                        settingComponentId={newSettingsData.componentId}
                        classForContainer={getClassForContainer(newSettingsData.componentId)}
                      />
                    );
                  case 'LINK_RENDERER':
                    return (
                      <LinkSettingsRow
                        key={key}
                        rowData={newSettingsData.rowData}
                        classForContainer={getClassForContainer()}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          );
        })}

        <LogoutUser />

        <div>
          <div className={'settings-app-version'}>
            {t('app_version')}: {getAppVersion()}
          </div>
        </div>
      </div>
    </div>
  );
};
