import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useReducer
} from 'react';
import eventbus from 'eventing-bus';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import ProductRow from './ProductRow';
import { PRODUCT_ROW_TYPES, reorderProduct, resetActiveProductId } from 'qs-data-manager/Products';
import EmptyProductList from './EmptyProductList';
import { selectedProducts } from 'qs-data-manager/Selected';

import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ProductsFilePicker from '../ProductsFilePicker';
import useDynamicSizedList from 'qs-hooks/dynamicSizedList';
import Loader from 'qs-components/Common/Loader';
import useMakeQuery from 'qs-hooks/useMakeQuery';
import {
  fetchCatalogueProductsHandler,
  getCachedCatalogueProductsList,
  handleCatalogueProductsCleanup
} from 'qs-helpers/Products/DataQueryHelper';
import { ActiveCatalogueProductListMeta } from '../context';
import {
  UNSET_PRODUCT_LIST_APPLY_FILTER,
  UNSET_PRODUCT_LIST_META_RESET,
  SET_REORDER_PRODUCTS
} from '../reducer';
import {
  productListFilterReducer,
  productListFilterInit,
  SET_PRODUCT_LIST_SEARCH,
  SET_PRODUCT_LIST_FILTERS,
  RESET_PRODUCT_LIST_FILTER,
  SET_PRODUCT_LIST_TAGS_FILTER,
  DELETE_CATALOGUE_FILTERS
} from './reducer';
import { productListFiltersExist } from 'qs-helpers/Products/ProductsListFilterHelper';
import { setCatalogueFilterTagsInCache } from 'qs-data-manager/CatalogueTags/Tags';
import ErrorIcon from 'qs-components/Common/ErrorIcon';
import ReorderBarIcon from 'qs-components/Common/ReorderBarIcon';
import Headline from './Headline';
import './styles.scss';
import { REORDER_ACTIVE, REORDER_DEACTIVE } from 'qs-helpers/Products/constants';
import {
  clearCachedShowcaseLinkDataForCatalogueId,
  setShowcaseLinkCacheForCatalogueId
} from 'qs-data-manager/Products/ProductShowcaseLinks';

const SortableDragHandle = SortableHandle(({ disabled, id }) => (
  <div
    className={`product-draggable-icon ${disabled ? ' disabled' : ''}`}
    id={`drag-product-${id}-icon`} // convert it to only id if wants to keep control only on icon.
  >
    <ReorderBarIcon color="#fff" />
  </div>
));

const SortableItem = sortableElement(
  ({ rowData, style, productIndex, reRenderListOnItemUpdate, getDragIcon }) => {
    return (
      <div key={productIndex} style={style}>
        <ProductRow
          productId={rowData}
          productIndex={productIndex}
          reRenderListOnItemUpdate={reRenderListOnItemUpdate}
          getDragIcon={getDragIcon}
        />
      </div>
    );
  }
);

const VirtualList = ({
  activeCatalogueId,
  innerListMounted,
  items,
  contentContainerRef,
  enableProductsReorder
}) => {
  const [productsListRef, itemHeightMap, reRenderListOnItemUpdate] = useDynamicSizedList();
  const scrollToTop = useCallback(() => {
    if (!contentContainerRef) {
      return;
    }
    contentContainerRef.scrollTo(0, 'start');
  }, [contentContainerRef]);

  useEffect(() => {
    scrollToTop();
  }, [activeCatalogueId, scrollToTop]);

  const innerRefElement = innerListRef => {
    innerListMounted(innerListRef);
  };

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const { productId } = data[index];
      const dragHandler = productId =>
        enableProductsReorder ? <SortableDragHandle id={productId} /> : null;

      return (
        <SortableItem
          key={productId}
          index={index}
          productIndex={index}
          style={style}
          rowData={productId}
          distance={1}
          reRenderListOnItemUpdate={reRenderListOnItemUpdate}
          activeCatalogueId={activeCatalogueId}
          disabled={!enableProductsReorder}
          getDragIcon={dragHandler}
        />
      );
    },
    [reRenderListOnItemUpdate, activeCatalogueId, enableProductsReorder]
  );

  const getItemHeight = useCallback(
    index => {
      const productData = items[index];
      if (!productData) {
        return PRODUCT_ROW_TYPES.PRODUCT_ROW.estimatedHeight;
      }
      const productHeight = itemHeightMap.current.get(productData.productId);
      if (productHeight !== undefined) {
        return productHeight;
      }

      return PRODUCT_ROW_TYPES.PRODUCT_ROW.estimatedHeight;
    },
    [items, itemHeightMap]
  );

  const handleScroll = ({ scrollTop }) => {
    if (productsListRef.current) {
      productsListRef.current.scrollTo(scrollTop);
    }
  };

  const itemKey = (index, data) => data[index].productId;
  return (
    <WindowScroller scrollElement={contentContainerRef} onScroll={handleScroll}>
      {() => (
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <List
              key={activeCatalogueId}
              ref={productsListRef}
              innerRef={innerRefElement}
              width={width}
              height={contentContainerRef.clientHeight}
              itemData={items}
              itemCount={items.length}
              itemKey={itemKey}
              itemSize={getItemHeight}
              overscanCount={PRODUCT_ROW_TYPES.overscanCount}
              className="window-products-list"
            >
              {renderRow}
            </List>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

const SortableVirtualList = sortableContainer(VirtualList);

export default ({ activeCatalogueId, toggleProductLibrary }) => {
  const searchDebouncer = useRef();

  //Save catalogue Id in ref so that it can be used in the effects without unnecessary re-triggers
  const savedCatalogueId = useRef();
  savedCatalogueId.current = activeCatalogueId;

  const [, setContentContainerRefMounted] = useState(null);
  const contentContainerRef = useRef(null);
  const contentContainerRefMounted = useCallback(ref => {
    if (ref) {
      contentContainerRef.current = ref;
      setContentContainerRefMounted(ref);
      return;
    }
    contentContainerRef.current = null;
  }, []);

  //Since some values can be set directly from the context and some must be processed
  //only on certain triggers such as post a timeout or button click, drive all filters
  //through the state.
  //TODO move all calculation in the parent context and make the context catalogue based
  const [
    {
      [activeCatalogueId]: {
        searchTerm,
        priceFilters,
        variantSelection,
        customFieldsSelection,
        selectedTags
      } = {}
    },
    setProductListFilter
  ] = useReducer(productListFilterReducer, activeCatalogueId, productListFilterInit);

  const {
    productListMeta: {
      currentSelectedTags,
      currentSearchTerm,
      currentPriceFilters,
      currentPriceFilterSelectedOption,
      currentVariantSelection,
      currentCustomFieldsSelection,
      customFieldsNumericOption,
      applyFilters,
      enableProductsReorder,
      reset
    },
    setProductListMeta
  } = useContext(ActiveCatalogueProductListMeta);

  const [{ loading, refreshing, data: productListData, error }] = useMakeQuery({
    cachedDataHandler: getCachedCatalogueProductsList,
    actionHandler: fetchCatalogueProductsHandler,
    cleanupHandler: handleCatalogueProductsCleanup,
    changeDependancy: [
      activeCatalogueId,
      selectedTags,
      searchTerm,
      priceFilters,
      variantSelection,
      customFieldsSelection
    ],
    globalLoader: false
  });

  useEffect(() => {
    const setReorderView = newValue =>
      setProductListMeta({ type: SET_REORDER_PRODUCTS, enableProductsReorder: newValue });
    const removeEnableEventBus = eventbus.on(REORDER_ACTIVE, () => setReorderView(true));
    const removeDisableEventBus = eventbus.on(REORDER_DEACTIVE, () => setReorderView(false));
    return () => {
      removeEnableEventBus();
      removeDisableEventBus();
    };
  }, [setProductListMeta]);

  useEffect(
    () => () =>
      setProductListFilter({ type: DELETE_CATALOGUE_FILTERS, catalogueId: activeCatalogueId }),
    [activeCatalogueId]
  );

  useEffect(() => {
    setShowcaseLinkCacheForCatalogueId(activeCatalogueId);
    return () => clearCachedShowcaseLinkDataForCatalogueId(activeCatalogueId);
  }, [activeCatalogueId]);

  useEffect(() => {
    if (typeof currentSearchTerm !== 'string') {
      setProductListFilter({
        type: SET_PRODUCT_LIST_SEARCH,
        catalogueId: savedCatalogueId.current
      });
    } else {
      searchDebouncer.current = setTimeout(() => {
        setProductListFilter({
          type: SET_PRODUCT_LIST_SEARCH,
          searchTerm: currentSearchTerm,
          catalogueId: savedCatalogueId.current
        });
      }, 200);
    }

    return () => clearTimeout(searchDebouncer.current);
  }, [currentSearchTerm]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }
    setProductListMeta({ type: UNSET_PRODUCT_LIST_APPLY_FILTER });
    setProductListFilter({
      type: SET_PRODUCT_LIST_FILTERS,
      currentPriceFilters,
      currentPriceFilterSelectedOption,
      currentVariantSelection,
      currentCustomFieldsSelection,
      customFieldsNumericOption,
      catalogueId: savedCatalogueId.current
    });
  }, [
    currentPriceFilters,
    currentPriceFilterSelectedOption,
    currentVariantSelection,
    currentCustomFieldsSelection,
    customFieldsNumericOption,
    applyFilters,
    setProductListMeta
  ]);

  useEffect(() => {
    setProductListFilter({
      type: SET_PRODUCT_LIST_TAGS_FILTER,
      currentSelectedTags,
      catalogueId: savedCatalogueId.current
    });
  }, [currentSelectedTags]);

  useEffect(() => {
    if (!reset) {
      return;
    }

    setProductListFilter({
      type: RESET_PRODUCT_LIST_FILTER,
      catalogueId: savedCatalogueId.current
    });
    setProductListMeta({ type: UNSET_PRODUCT_LIST_META_RESET });
  }, [reset, setProductListMeta]);

  //Copy data in state so that after re-ordering the list can be updated in place.
  //TODO reorder directly in the cache if possible and then render that on the screen
  const [productList, setProductList] = useState((productListData || {}).productsList);

  useLayoutEffect(() => {
    if (!productListData || !Array.isArray(productListData.productsList)) {
      return;
    }

    const { productsList, tags, inStockProductCount, outOfStockProductCount } = productListData;
    setProductList(productsList);
    selectedProducts.refreshItemsAndSelections(productsList.map(product => product.productId));
    //Refresh the catalogue tags based on the applied filters
    setCatalogueFilterTagsInCache({
      catalogueId: savedCatalogueId.current,
      updates: { tags, inStockCount: inStockProductCount, outOfStockCount: outOfStockProductCount },
      productFilter: true
    });
  }, [productListData]);

  const innerListRef = useRef(null);

  useEffect(() => {
    return () => resetActiveProductId();
  }, [activeCatalogueId]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }

      const newList = arrayMove(productList, oldIndex, newIndex).map((row, index) => ({
        ...row,
        position: index
      }));
      setProductList(newList);
      reorderProduct({
        newProductList: newList,
        catalogueId: activeCatalogueId,
        oldIndex,
        newIndex
      });
    },
    [productList, activeCatalogueId]
  );

  const setMarginForList = useCallback((buttonsHeight = 0) => {
    if (!innerListRef.current || !innerListRef.current.style) {
      return;
    }

    innerListRef.current.style.marginBottom = `${buttonsHeight}px`;
  }, []);

  const innerListMounted = useCallback(ref => {
    innerListRef.current = ref;
  }, []);

  const renderList = () => {
    const filtersApplied = productListFiltersExist({
      customFieldsSelection,
      priceFilters,
      searchTerm,
      selectedTags,
      variantSelection
    });

    if (error) {
      return (
        <div id="productListLoaderContainer" className="listErrorContainer">
          <ErrorIcon width={40} height={40} />
          <p className="errorText">Something went wrong while fetching products</p>
        </div>
      );
    }

    if (loading || (filtersApplied && refreshing)) {
      return (
        <div id={'productListLoaderContainer'}>
          <Loader size={'large'} color={'white'} />
        </div>
      );
    }

    if (!Array.isArray(productList) || productList.length === 0) {
      if (filtersApplied) {
        return (
          <>
            <Headline catalogueId={activeCatalogueId} />
            <EmptyProductList.SEARCH />
          </>
        );
      }

      return <EmptyProductList.NORMAL toggleProductLibrary={toggleProductLibrary} />;
    }

    if (!contentContainerRef.current) {
      return null;
    }

    return (
      <div className={'AutoSizerContainer'}>
        <Headline catalogueId={activeCatalogueId} />
        <SortableVirtualList
          items={productList}
          innerListMounted={innerListMounted}
          onSortEnd={onSortEnd}
          contentContainerRef={contentContainerRef.current}
          pressDelay={150}
          lockAxis={'y'}
          activeCatalogueId={activeCatalogueId}
          enableProductsReorder={enableProductsReorder}
        />
      </div>
    );
  };

  return (
    <>
      <div className="productsListContentContainer" ref={contentContainerRefMounted}>
        {renderList()}
      </div>
      <ProductsFilePicker
        toggleProductLibrary={toggleProductLibrary}
        onButtonsVisible={setMarginForList}
      />
    </>
  );
};
