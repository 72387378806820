import eventbus from 'eventing-bus';
import firebase from 'qs-config/FirebaseConfig';
import Api from 'qs-services/Api';
import { attachDefaultListeners } from 'qs-services/Firebase';
import { navigateToPath, showSideBars, hideSideBars } from 'qs-data-manager';
import { saveCurrentPlan, saveFeatureLimits } from 'qs-data-manager/FeatureUsage';
import { formWindowEndpointForLogin } from 'qs-helpers';
import {
  COMPANY_LOCAL_STORAGE_KEYS,
  setCompanyLevelGoldRateAndProductTypeInCache,
  setCompanyMetaInCache
} from 'qs-data-manager/Company';
import { setSentryContext, reportError, resetSentryUser } from 'qs-helpers/ErrorReporting';
import {
  generateUniqueDeviceIdIfNotExists,
  saveIdToNativeStorage,
  getCachedUniqueDeviceId
} from 'qs-helpers/DeviceIdGenerator';
import { processUserChange } from 'qs-image-upload/ImageUploader';
import CacheRequest from './CacheRequest';
import { deleteSavedData } from 'qs-helpers/ClearSavedData';
import { setUserMetaInCache } from './User';
import { failPendingImagesForThisDevice } from 'qs-helpers/ProcessUploadedImage';
import Mixpanel from './Mixpanel';
import { ATTACH_FIREBASE_LISTENERS } from 'qs-helpers/constants';
import { APP_LANGUAGE_CHANGED_EB_KEY, ROUTE_MAP } from 'qs-helpers/CompanySettings/constants';
import { APP_LANGUAGES } from '../i18N';

export const AUTH_PROVIDERS = {
  GOOGLE: 'GOOGLE',
  WHATSAPP: 'WHATSAPP',
  APPLE: 'APPLE'
};

export const IS_LOGGING_IN = {
  eventbusKey: 'IS_LOGGING_IN',
  isLoggingIn: false
};

const FIREBASE_ERROR_CODES = {
  CANCELLED_POPUP: 'auth/cancelled-popup-request',
  CLOSED_BY_USER: 'auth/popup-closed-by-user',
  NETWORK_REQ_FAILED: 'auth/network-request-failed',
  WEB_STORAGE_UNSUPPORTED: 'auth/web-storage-unsupported'
};

const WHATSAPP_ERROR_CODES = {
  CODE_NOT_FOUND: 'CODE_NOT_FOUND',
  WAIT: 'WAIT'
};

export const checkAndHandleUserAccessToWeb = (user, currentPlan = {}) => {
  const { features: { DESKTOP: { allowed } = {} } = {}, planType } = currentPlan;
  if (!allowed) {
    userUnauthorized({
      email: user.email,
      reason: `User is on ${planType} plan`,
      currentPlan: planType
    });
  }

  return allowed;
};

export const getIsLoggingIn = () => IS_LOGGING_IN.isLoggingIn;

export const saveDataAndRedirectToCatalogue = async ({ user, company }) => {
  try {
    const currentPlan = await Api.getCurrentPlan();
    saveCurrentPlan(currentPlan);
    const allowed = checkAndHandleUserAccessToWeb(user, currentPlan);
    if (!allowed) {
      return;
    }

    const featureLimit = await Api.getPaymentPlan();
    const { productType = '' } = (await Api.getGoldRatesAndProductType()) || {};
    setCompanyLevelGoldRateAndProductTypeInCache({ data: productType });
    saveFeatureLimits(featureLimit);
    navigateToPath(`/${ROUTE_MAP.CATALOGUES}`);
    showSideBars();
    attachDefaultListeners({
      companyId: company.id,
      userId: user.id
    });
    generateUniqueDeviceIdIfNotExists({ companyId: company.id });
    await failPendingImagesForThisDevice();
  } catch (err) {
    eventbus.publish(IS_LOGGING_IN.eventbusKey, {
      message: err,
      didError: true
    });
    await firebase.auth().signOut();
    reportError(err);
  } finally {
    closeLoginLoader();
  }
};

const handleFirebaseAttachListeners = async count => {
  try {
    localStorage.setItem(ATTACH_FIREBASE_LISTENERS, 'true');
    eventbus.publish(ATTACH_FIREBASE_LISTENERS);
  } catch (err) {
    if (count < 3) {
      await handleFirebaseAttachListeners(count + 1);
      return;
    }
    // show error message
  }
};

// On login will be called when the user is not logged in and logs in explicitly.
export const onLogin = async (provider, id = '') => {
  try {
    let selectedProvider = null;
    let isWhatsappPhoneAuth = false;
    let result = null;
    let whatsappToken = null;

    if (provider === AUTH_PROVIDERS.GOOGLE) {
      selectedProvider = new firebase.auth.GoogleAuthProvider();
      selectedProvider.addScope('profile');
      selectedProvider.addScope('email');
      selectedProvider.setCustomParameters({ prompt: 'select_account' });
    } else if (provider === AUTH_PROVIDERS.WHATSAPP) {
      isWhatsappPhoneAuth = true;
      if (id) {
        const newToken = await Api.getWhatsappLoginToken({ code: id });
        if (newToken.status === 'SUCCESS') {
          selectedProvider = true;
          whatsappToken = newToken.token;
        } else {
          throw newToken.status;
        }
      } else {
        const numberReceivedFromApi = await Api.getWhatsappNumber();
        if (numberReceivedFromApi)
          return (window.location.href = `https://wa.me/${numberReceivedFromApi.whatsappNumber}?text=Sign me into QuickSell Web`);
      }
    } else if (provider === AUTH_PROVIDERS.APPLE) {
      selectedProvider = new firebase.auth.OAuthProvider('apple.com');
    }

    if (!selectedProvider) {
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        message: 'Use google to log in',
        didError: true
      });
      return;
    }

    IS_LOGGING_IN.isLoggingIn = true;

    if (whatsappToken) {
      result = await firebase.auth().signInWithCustomToken(whatsappToken);
    } else {
      result = await firebase.auth().signInWithPopup(selectedProvider);
    }

    showLoginLoader();

    if (result.user) {
      Mixpanel.sendEvent({
        eventName: 'signed_in_successfully',
        props: {
          provider
        }
      });

      const { user: userAuthData } = result;

      await Api.login({
        name: userAuthData.displayName,
        photo: userAuthData.photoURL,
        isWhatsappPhoneAuth
      });

      handleFirebaseAttachListeners(1);

      const { user, company } = await Api.getCompanyAndUserObject();
      if (!!company && company.id) {
        Api.getAppLanguage(company.id)
          .then(({ value: languageCode = 'en_US' }) => {
            if (Object.keys(APP_LANGUAGES).indexOf(languageCode) > -1) {
              localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE, languageCode);
              eventbus.publish(APP_LANGUAGE_CHANGED_EB_KEY, languageCode);
            }
          })
          .catch(() => {
            localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.APP_LANGUAGE, 'en_US');
            eventbus.publish(APP_LANGUAGE_CHANGED_EB_KEY, 'en_US');
          });
      }

      setSentryContext({
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
        companyId: company ? company.id : undefined
      });
      setCompanyMetaInCache({ data: company });
      setUserMetaInCache({ data: user });
      if (company.name && user.phone) {
        saveDataAndRedirectToCatalogue({ user, company });
      } else {
        eventbus.publish(IS_LOGGING_IN.eventbusKey, {
          setupIncomplete: true,
          isLoggingIn: true,
          company,
          user
        });
      }
    } else {
      IS_LOGGING_IN.isLoggingIn = false;
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        isNewUser: true,
        isLoggingIn: false,
        didError: false,
        message: ''
      });
    }
  } catch (error) {
    closeLoginLoader();
    if (
      error &&
      (error.code === FIREBASE_ERROR_CODES.CANCELLED_POPUP ||
        error.code === FIREBASE_ERROR_CODES.CLOSED_BY_USER ||
        error.code === FIREBASE_ERROR_CODES.NETWORK_REQ_FAILED)
    ) {
      return;
    }
    if (error.code === FIREBASE_ERROR_CODES.WEB_STORAGE_UNSUPPORTED) {
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        webStorageUnsupported: true
      });
      return;
    }
    if (
      error &&
      (error === WHATSAPP_ERROR_CODES.CODE_NOT_FOUND || error === WHATSAPP_ERROR_CODES.WAIT)
    ) {
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        message: 'whatsappCodeError',
        didError: true
      });
      return;
    }
    eventbus.publish(IS_LOGGING_IN.eventbusKey, {
      message: error,
      didError: true
    });
    await firebase.auth().signOut();
    reportError(error);
  }
};

export const showLoginLoader = () => {
  IS_LOGGING_IN.isLoggingIn = true;
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: true
  });
};

export const closeLoginLoader = () => {
  IS_LOGGING_IN.isLoggingIn = false;
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: false
  });
};

export const userUnauthorized = ({ email, reason = 'UNAUTHORIZED', currentPlan }) => {
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: false,
    notAllowedToUse: true,
    currentPlan,
    userEmail: email,
    didError: false,
    message: ''
  });
  onLogout();
};

export const onLogout = ({ shouldNavigate = true } = {}) => {
  try {
    Mixpanel.sendEvent({ eventName: 'logout' });
    hideSideBars();

    if (shouldNavigate) {
      try {
        navigateToPath(`/${ROUTE_MAP.LOGIN}`);
      } catch (error) {
        window.location.href = formWindowEndpointForLogin();
      }
    }

    firebase.auth().signOut();
    processUserChange();
    resetSentryUser();
    CacheRequest.reset();
    const currentDeviceId = getCachedUniqueDeviceId();
    localStorage.clear();

    /*
      Even though the entire local storage data is cleared, do not clear the
      unique device Id that was originally generated. Save it in local storage
      again
    */
    saveIdToNativeStorage(currentDeviceId);
    deleteSavedData();
  } catch (error) {
    reportError(error);
  }
};
