import React, { Component } from 'react';
import Route from 'route-parser';
import { getCompanySettings, getCatalogueShareText } from 'qs-data-manager/Company';
import SettingsLeftPanel from './SettingsLeftPanel/SettingsLeftPanel';
import InventorySettings from './InventorySettings/InventorySettings';
import CatalogueSettings from './CatalogueSettings/CatalogueSettings';
import LanguageSettings from './LanguageSettings/LanguageSettings';
import SettingsIframeComponent from './SettingsIframeComponent';
import CheckoutSettings from './CheckoutSettings';
import TaxSettings from './TaxSettings';
import SuggestionFeedback from './SuggestionFeedback.js/SuggestionFeedback';
import CompanyProfile from './CompanyProfile/CompanyProfile';
import DomainSettings from './DomainSettings/DomainSettings';
import WebhookSettings from './WebhookSettings';
import HtmlPageBuilder from './HtmlPageBuilder/HtmlPageBuilder';
import {
  ROUTE_MAP,
  SETTINGS_COMPONENT_ID_MAP,
  SETTINGS_COMPONENT_ROUTE_MAP
} from 'qs-helpers/CompanySettings/constants';
import {
  getRouteParamFromSettingsComponentId,
  getSettingsComponentIdFromRouteParam
} from 'qs-helpers/navigation';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';

class CompanySettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsComponentId: SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME
    };

    this.componentIdMapping = CompanySettings.createListOfSettingsOptions();
  }

  static createListOfSettingsOptions() {
    const customIframeStyle = {
      width: '100%',
      border: 'none'
    };
    const { t } = getI18N();
    const componentIdMapping = {
      [SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME]: CompanyProfile,
      [SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].TITLE)}
          route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].IFRAME_ROUTE}
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PLANS_BILLING].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.DOMAIN_SETTINGS]: DomainSettings,
      [SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].TITLE)}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PAYMENT_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.SHIPPING_COST_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_THEMES_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_GRID_TILE]: HtmlPageBuilder,
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_LIST_TILE]: HtmlPageBuilder,
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PRODUCT_MAGAZINE_TILE]: HtmlPageBuilder,
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_CATALOGUE_GRID_TILE]: HtmlPageBuilder,
      [SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.OTHER_PAGES_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_PAGES_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FOOTER_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FONT_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CREATE_MENU_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].TITLE)}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_OPTIONS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS]: () => {
        return (
          <SettingsIframeComponent
            id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].TITLE}
            title={t(
              SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS].TITLE
            )}
            route={
              SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS]
                .IFRAME_ROUTE
            }
            removeBeginningHash={
              SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.METAL_RATES_OPTIONS]
                .REMOVE_HASH
            }
            iframeStyle={customIframeStyle}
          />
        );
      },
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].TITLE)}
          route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].IFRAME_ROUTE}
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_FIELDS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.WEBHOOKS_SETTINGS]: WebhookSettings,
      [SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.JEWELLERY_PRICE_DISPLAY]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.TAXES_SETTINGS]: TaxSettings,
      [SETTINGS_COMPONENT_ID_MAP.INVENTORY_SETTINGS]: InventorySettings,
      [SETTINGS_COMPONENT_ID_MAP.LANGUAGE_SETTINGS]: LanguageSettings,
      [SETTINGS_COMPONENT_ID_MAP.CATALOGUE_SETTINGS]: CatalogueSettings,
      [SETTINGS_COMPONENT_ID_MAP.CHECKOUT_SETTINGS]: CheckoutSettings,
      [SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]: () => (
        <SettingsIframeComponent
          id={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]
              .TITLE
          }
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]
              .TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.CUSTOM_CHECKOUT_FORM_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRODUCT_TYPE_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].TITLE)}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.MANAGE_API_KEY].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].TITLE)}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.PRIVACY_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].TITLE)}
          route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].IFRAME_ROUTE}
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COUPON_PAGE].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].TITLE)}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REPORTS_SETTINGS].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.FACEBOOK_PIXEL_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS]: () => (
        <SettingsIframeComponent
          id={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS].TITLE
          }
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_ANALYTICS_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]: () => (
        <SettingsIframeComponent
          id={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]
              .TITLE
          }
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]
              .TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.GOOGLE_SEARCH_CONSOLE_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.WEBINAR]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].TITLE}
          title={t(SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].TITLE)}
          route={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].IFRAME_ROUTE}
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.WEBINAR].REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS]: () => (
        <SettingsIframeComponent
          id={SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS].TITLE}
          title={t(
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS].TITLE
          )}
          route={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS]
              .IFRAME_ROUTE
          }
          removeBeginningHash={
            SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.REFERRAL_CODE_SETTINGS]
              .REMOVE_HASH
          }
          iframeStyle={customIframeStyle}
        />
      ),
      [SETTINGS_COMPONENT_ID_MAP.SUGGESTION_FEEDBACKS]: SuggestionFeedback
    };
    return componentIdMapping;
  }

  componentDidMount() {
    getCompanySettings();
    getCatalogueShareText();
    const routeParam = this.getRouteParam(this.props);
    const defaultRoute = SETTINGS_COMPONENT_ROUTE_MAP[SETTINGS_COMPONENT_ID_MAP.COMPANY_NAME].ROUTE;
    const settingsComponentId = getSettingsComponentIdFromRouteParam(
      !!routeParam ? routeParam : defaultRoute
    );
    if (!!settingsComponentId) {
      this.setState({ settingsComponentId });
    }
    if (!routeParam || (!!routeParam && !settingsComponentId)) {
      this.props.history.replace(`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${defaultRoute}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRouteParam = this.getRouteParam(prevProps);
    const routeParam = this.getRouteParam(this.props);
    if (prevRouteParam !== routeParam && !!routeParam) {
      const settingsComponentId = getSettingsComponentIdFromRouteParam(routeParam);
      if (settingsComponentId) {
        this.setState({ settingsComponentId });
      }
    }
  }

  getRouteParam(prop) {
    const {
      location: { pathname }
    } = prop;
    let routeParams = new Route(`/${ROUTE_MAP.ACCOUNT_SETTINGS}/:page`).match(pathname);
    if (!routeParams) {
      routeParams = new Route(`/${ROUTE_MAP.ACCOUNT_SETTINGS}/:page/:state`).match(pathname);
    }
    return routeParams.page;
  }

  handleSettingsItemClick = settingsComponentId => {
    const routeParam = getRouteParamFromSettingsComponentId(settingsComponentId);
    if (routeParam) {
      this.props.history.push(`/${ROUTE_MAP.ACCOUNT_SETTINGS}/${routeParam}`);
    }
    this.setState({ settingsComponentId });
  };

  render() {
    const { settingsComponentId } = this.state;
    const ComponentToRender = this.componentIdMapping[settingsComponentId];

    return (
      <>
        <SettingsLeftPanel
          handleSettingsItemClick={this.handleSettingsItemClick}
          selectedId={settingsComponentId}
        />
        <div className="settingsContainer">
          <ComponentToRender />
        </div>
      </>
    );
  }
}

export default CompanySettings;
