export const VARIANT_ROW = {
  type: 'VARIANT_ROW',
  estimatedHeight: 80,
  width: 50,
  overscanCount: 5
};

export const VARIANT_INVENTORY_ROW = {
  type: 'VARIANT_INVENTORY_ROW',
  estimatedHeight: 50,
  overscanCount: 5
};

export const PRODUCT_OPTIONS = {
  type: 'PRODUCT_OPTIONS',
  estimatedHeight: 50,
  overscanCount: 10
};

export const VARIANT_ADD_TABS = {
  COLORS: 'COLORS',
  SIZES: 'SIZES'
};

export const OPTION_STATIC_COLORS = [
  {
    label: 'Red',
    rgb: [247, 66, 70]
  },
  {
    label: 'Orange',
    rgb: [248, 140, 73]
  },
  {
    label: 'Yellow',
    rgb: [249, 211, 79]
  },
  {
    label: 'Green',
    rgb: [114, 248, 79]
  },
  {
    label: 'Blue',
    rgb: [75, 227, 248]
  },
  {
    label: 'Neon blue',
    rgb: [66, 77, 246]
  },
  {
    label: 'Pink',
    rgb: [247, 69, 162]
  }
];

export const FROM_VARIANTS = 'VARIANTS';

export const FROM_VARIANTS_TRACK = 'VARIANTS_TRACK';

export const VARIANT_INVENTORY_OPERATION = 'VARIANT_INVENTORY_OPERATION';

export const VARIANT_OPERATION_KEYS = {
  TRACK_INVENTORY: 'TRACK_INVENTORY',
  STOCK_CHANGE: 'STOCK_CHANGE'
};
