import * as Sentry from '@sentry/browser';
import eventbus from 'eventing-bus';
import cloneDeep from 'lodash.clonedeep';
import { setMoqForEntityApi } from 'qs-api/Moq/api';
import { db } from 'qs-config/FirebaseConfig';
import {
  clearCatalogueDataFromNative,
  createCatalogueInNative,
  deleteCataloguesFromNative,
  updateCatalogueTitleInNative,
  upsertCatalogueRowInNative
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import {
  CATALOGUES_LAST_FETCH_TS,
  convertStringToValidRegexExp,
  debouncer,
  DEBOUNCER_TYPE
} from 'qs-helpers';
import { upsertChangesDataToCatalogueRowMetaCache } from 'qs-helpers/Catalogues/ResponseProcessor';
import { registerCleanupHandler } from 'qs-helpers/ClearSavedData';
import { formatNumberForExcelUploadApi } from 'qs-helpers/CSVUploader';
import { MOQ_CATALOGUE_ENTITY } from 'qs-helpers/Moq/constants';
import Api from 'qs-services/Api';
import { toggleGlobalLoader } from 'qs-services/Helpers';
import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import { setPublishToHomepageInCache } from './Catalogues/PublishToHomepage';
import { getCompanyOrderOnOOS, getCompanyStockSetting, setCompanySettingsInCache } from './Company';
import { ACTIVE_PRODUCT_ID_META } from './Products';
import { selectedCatalogue, selectedLabels } from './Selected';

let CHANGES_DEBOUNCER_ID = null;
const CATALOGUE_META_DEBOUNCER = {
  key: 'CATALOGUE_META_DEBOUNCER_KEY',
  timeInMs: 200,
  type: DEBOUNCER_TYPE.ADD
};

export const CATALOGUE_SHARE_TYPES = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI'
};

export const CREATING_NEW_CATALOGUE = {
  NEW_CATALOGUE_EB_KEY: 'TOGGLE_NEW_CATALOGUE',
  CREATING_CATALOGUE: 'CREATING_NEW',
  DELETE_CATALOGUE: 'DELETE_NEW',
  SAVE_CATALOGUE: 'SAVE_CATALOGUE'
};

export const CATALOGUE_SEARCH = 'CATALOGUE_SEARCH';
export const CATALOGUE_SEARCH_STATE = 'CATALOGUE_SEARCH_STATE';
export const CATALOGUE_SEARCH_TERM = 'CATALOGUE_SEARCH_TERM';

export let ACTIVE_CATALOGUE_META = {
  eventbusKey: 'ACTIVE_CATALOGUE_META',
  catalogueId: null,
  localStorageKey: 'ACTIVE_CATALOGUE_ID'
};

export const ACTIVE_CATALOGUE_META_TITLE = {
  eventbusKey: id => `ACTIVE_CATALOGUE_META_TITLE-${id}`
};

export const CATALOGUE_ROW_TYPES = {
  SEARCH_RESULT: {
    type: 'SEARCH_RESULT',
    height: 40
  },
  CATALOGUE_META: {
    type: 'CATALOGUE_META',
    height: 114,
    overscanCount: 5
  },
  SKELETON_CATALOGUE: {
    type: 'SKELETON_CATALOGUE',
    height: 114
  }
};

export const CSV_UPLOADER_EB_KEY = 'CSV_UPLOADER_EB_KEY';

export const EXCEL_UPLOAD_META = {
  RENDER_ORDER: [
    'ID', // Need to be added again once fixed
    'IMAGE',
    'VIDEO',
    'TITLE',
    'SKU',
    'PRICE',
    'DISCOUNT',
    'DESCRIPTION',
    'ENTITY_TYPE',
    'KARAT',
    'PURITY',
    'GROSS_WEIGHT',
    'NET_WEIGHT',
    'MAKING_CHARGES',
    'WASTAGE_PERCENT',
    'WASTAGE_ON',
    'OTHER_CHARGES',
    'TAGS',
    'INVENTORY',
    'TAX_TYPE',
    'TAX_PERCENTAGE',
    'SET_QUANTITY',
    'SET_NAME',
    'SET_TYPE',
    'SIZES',
    'SIZES_SET_QUANTITY',
    'COLORS',
    'COLORS_SET_QUANTITY',
    'PARENT_SKU',
    'WEIGHT',
    'NOTES',
    'CUSTOM_FIELDS',
    'CUSTOM_VARIANTS'
    // 'METAL',
  ],
  ID: {
    id: 'ID',
    title: 'Product id',
    mapOnPattern: /^Product id$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Product id',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'id',
    apiChangesKey: 'PRODUCT_ID'
  },
  IMAGE: {
    id: 'IMAGE',
    title: 'Product picture url',
    mapOnPattern: /^Product picture url$|picture|pictures|photo|photos|image|images/i,
    ifMultipleTitlesMatch: 'MATCH_ALL',
    previewTitle: 'Additional pictures',
    acceptsMultipleColumns: true,
    getApiFormattedValue: data => (data && data.length ? data : undefined),
    apiKey: 'imageUrl'
  },
  VIDEO: {
    id: 'VIDEO',
    title: 'Product video url',
    mapOnPattern: /^Product video url$|video|videos/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Additional video',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? data : undefined),
    apiKey: 'videoUrl'
  },
  TITLE: {
    id: 'TITLE',
    title: 'Product title',
    mapOnPattern: /^product name$|^Product title$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Title',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'name',
    apiChangesKey: 'PRODUCT_TITLE'
  },
  SKU: {
    id: 'SKU',
    title: 'Sku',
    mapOnPattern: /^Sku$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Sku',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'sku',
    apiChangesKey: 'PRODUCT_SKU'
  },
  PARENT_SKU: {
    id: 'PARENT_SKU',
    title: 'Parent product sku',
    mapOnPattern: /^Parent product sku$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Parent product sku',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'parentSku'
  },
  COLORS: {
    id: 'COLORS',
    title: 'Colors',
    mapOnPattern: /^Colo[u]?r[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_NONE',
    previewTitle: 'Colors',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'colors'
  },
  COLORS_SET_QUANTITY: {
    id: 'COLORS_SET_QUANTITY',
    title: 'Colors set quantity',
    mapOnPattern: /^Colo[u]?r[s]? set (?:quantity|qty)$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Colors set quantity',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'colorsSetQuantity'
  },
  SIZES: {
    id: 'SIZES',
    title: 'Sizes',
    mapOnPattern: /^size[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_NONE',
    previewTitle: 'Sizes',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'sizes'
  },
  SIZES_SET_QUANTITY: {
    id: 'SIZES_SET_QUANTITY',
    title: 'Sizes Set quantity',
    mapOnPattern: /^size[s]? set (?:quantity|qty)$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Sizes set quantity',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'sizesSetQuantity'
  },
  PRICE: {
    id: 'PRICE',
    title: 'Product price',
    mapOnPattern: /^Product price$|^mrp$|^price$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Product price',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'price'
  },
  DISCOUNT: {
    id: 'DISCOUNT',
    title: 'Discounted price',
    mapOnPattern: /^Discounted price$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Discounted price',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'discount'
  },
  SET_QUANTITY: {
    id: 'SET_QUANTITY',
    title: 'Set quantity',
    mapOnPattern: /^set (?:quantity|qty)$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Set quantity',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'setQuantity'
  },
  SET_NAME: {
    id: 'SET_NAME',
    title: 'Set name',
    mapOnPattern: /^set (?:name|title)$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Set name',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'setName'
  },
  SET_TYPE: {
    id: 'SET_TYPE',
    title: 'Set type',
    mapOnPattern: /^set type$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Set type',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'setType'
  },
  DESCRIPTION: {
    id: 'DESCRIPTION',
    title: 'Product description',
    mapOnPattern: /^Product description$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Description',
    acceptsMultipleColumns: true,
    getApiFormattedValue: data => (data && data.length ? data.join('') : undefined),
    apiKey: 'description'
  },
  TAGS: {
    id: 'TAGS',
    title: 'Product sub-categories',
    mapOnPattern: /\btag[s]?\b/i,
    ifMultipleTitlesMatch: 'MATCH_ALL',
    previewTitle: 'Product sub-categories',
    acceptsMultipleColumns: true,
    getApiFormattedValue: data => (data && data.length ? data : undefined),
    apiKey: 'tags'
  },
  INVENTORY: {
    id: 'INVENTORY',
    title: 'Available quantity',
    mapOnPattern: /^Available quantity$|^inventory$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Available quantity',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'inventory'
  },
  TAX_TYPE: {
    id: 'TAX_TYPE',
    title: 'Tax type',
    mapOnPattern: /^Tax type$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Tax type',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'taxType'
  },
  TAX_PERCENTAGE: {
    id: 'TAX_PERCENTAGE',
    title: 'Tax percentage',
    mapOnPattern: /^Tax percentage$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Tax percentage',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'taxPercentage'
  },
  WEIGHT: {
    id: 'WEIGHT',
    title: 'Weight',
    mapOnPattern: /\bweight[s]?\b/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Weight',
    acceptsMultipleColumns: false,
    fieldType: 'NUMBER',
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'weight'
  },
  NOTES: {
    id: 'NOTES',
    title: 'Private notes',
    mapOnPattern: /^Private notes$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Private notes',
    acceptsMultipleColumns: true,
    getApiFormattedValue: data => (data && data.length ? data.join('') : undefined),
    apiKey: 'notes'
  },
  CUSTOM_FIELDS: {
    id: 'CUSTOM_FIELDS',
    title: 'Custom fields',
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    disabled: true,
    componentType: 'LOADER',
    acceptsMultipleColumns: false,
    previewTitle: 'Custom Fields',
    apiKey: 'customFields',
    excludeCurrent: true,
    ignoreMissingPreview: true,
    mapOnPattern: fieldName =>
      new RegExp(fieldName ? `^${convertStringToValidRegexExp(fieldName)}$` : '^$', 'i'),
    getPreviewFormattedValue: () => '',
    parentSection: true,
    customStyles: {
      color: '#22A86F'
    },
    hasSubcomponents: true
  },
  CUSTOM_VARIANTS: {
    id: 'CUSTOM_VARIANTS',
    title: 'Custom Variants',
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    disabled: true,
    componentType: 'LOADER',
    acceptsMultipleColumns: false,
    previewTitle: 'Custom Variants',
    excludeCurrent: true,
    ignoreMissingPreview: false,
    parentSection: true,
    getPreviewFormattedValue: () => '',
    customStyles: {
      color: '#22A86F'
    },
    appendKeyToProductBaseObject: true,
    hasSubcomponents: true
  },
  ENTITY_TYPE: {
    id: 'ENTITY_TYPE',
    title: 'Entity Type',
    mapOnPattern: /^Entity type|^Metal type|^Metal/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Entity Type',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}`.toUpperCase() : undefined),
    apiKey: 'entityType'
  },
  KARAT: {
    id: 'KARAT',
    title: 'Karat',
    mapOnPattern: /^karat$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Karat',
    fieldType: 'NUMBER',
    acceptsMultipleColumns: false,
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'karat'
  },
  PURITY: {
    id: 'PURITY',
    title: 'Purity',
    mapOnPattern: /^purity$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Purity',
    fieldType: 'NUMBER',
    acceptsMultipleColumns: false,
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'purity'
  },
  WASTAGE_PERCENT: {
    id: 'WASTAGE_PERCENT',
    title: 'Wastage %',
    mapOnPattern: /^wastage$|^wastage %$|^wastage percent$|^wastage percentage$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Wastage',
    acceptsMultipleColumns: false,
    getPreviewFormattedValue: data => (data && data.length ? data.join(' ') : undefined),
    getApiFormattedValue: data => {
      if (Array.isArray(data) && data.length > 0 && data[0]) {
        const value = parseFloat(data[0]);
        return isNaN(value) ? undefined : value;
      }

      return undefined;
    },
    apiKey: 'wastagePercent'
  },
  WASTAGE_ON: {
    id: 'WASTAGE_ON',
    title: 'Wastage on',
    mapOnPattern: /^wastage on$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Wastage On',
    acceptsMultipleColumns: false,
    getPreviewFormattedValue: data => (data && data.length ? data.join(' ') : undefined),
    getApiFormattedValue: data => {
      if (Array.isArray(data) && data.length > 0 && data[0]) {
        const value = data[0];
        return value
          .trim()
          .replace(' ', '_')
          .replace('-', '_')
          .toUpperCase();
      }

      return undefined;
    },
    apiKey: 'wastageOn'
  },
  GROSS_WEIGHT: {
    id: 'GROSS_WEIGHT',
    title: 'Gross weight',
    mapOnPattern: /^gross weight[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Gross weight',
    fieldType: 'NUMBER',
    acceptsMultipleColumns: false,
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'grossWeight'
  },
  NET_WEIGHT: {
    id: 'NET_WEIGHT',
    title: 'Net weight',
    mapOnPattern: /^net weight[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Net weight',
    fieldType: 'NUMBER',
    acceptsMultipleColumns: false,
    getApiFormattedValue: formatNumberForExcelUploadApi,
    apiKey: 'netWeight'
  },
  MAKING_CHARGES: {
    id: 'MAKING_CHARGES',
    title: 'Making charges',
    mapOnPattern: /^making charge[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Making charges',
    acceptsMultipleColumns: false,
    getApiFormattedValue: data => (data && data.length ? `${data[0]}` : undefined),
    apiKey: 'makingCharge'
  },
  OTHER_CHARGES: {
    id: 'OTHER_CHARGES',
    title: 'Other charges',
    mapOnPattern: /^(?!.*making).*[ ]charge[s]?$/i,
    ifMultipleTitlesMatch: 'MATCH_TITLE',
    previewTitle: 'Other Charges',
    acceptsMultipleColumns: true,
    getPreviewFormattedValue: data => (data && data.length ? data.join(' ') : undefined),
    getApiFormattedValue: (data, metaData) => {
      if (metaData && metaData.columns) {
        const otherCharges = [];
        for (let i = 0; i < metaData.columns.length; i++) {
          otherCharges.push({ type: metaData.columns[i], amount: Number(data[i]) });
        }
        return otherCharges && otherCharges.length ? otherCharges : undefined;
      }
      return data && data.length ? data : undefined;
    },
    apiKey: 'otherCharges'
  }
};
export let SHARE_LINK_PROMISE = {};

export let CATALOGUE_META_REQUEST_SEND = {};

// Catalogue list listeners
const attachCatalogueIdsListener = listener => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};
const removeCatalogueIdsListener = listener => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};
const getAllCatalogueIds = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  const apiName = connector.CATALOGUE_LIST_META.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      nativeStorageKey: connector.CATALOGUE_LIST_META.nativeStorageKey
    }
  });
};

// Catalogue row meta listeners
const attachCatalogueMetaListener = (listener, key) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  CacheRequest.attachListener(`${sharedCacheKey}${key}`, listener);
};

const removeAttachCatalogueMetaListener = (listener, key) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  CacheRequest.removeListener(`${sharedCacheKey}${key}`, listener);
};

const catalogueMetaBatchCallback = (response, error) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  if (error) {
    return;
  }

  if (!!response && !!response.catalogueMeta && typeof response.catalogueMeta === 'object') {
    Object.keys(response.catalogueMeta).forEach(id => {
      const cache = response.catalogueMeta[id];
      const key = `${sharedCacheKey}${id}`;
      CATALOGUE_META_REQUEST_SEND[id] = true;
      CacheRequest.setCacheForKey(key, cache);
    });
  }
};

const catalogueMetaDebounceCallback = (multipleBatchedCatalogueIds = []) => {
  const batchedCatalogueIds = {};
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;

  multipleBatchedCatalogueIds.forEach(batchedCatalogueId => {
    batchedCatalogueId.forEach(catalogueId => {
      if (!catalogueId) {
        return;
      }

      const ifExists = !!CATALOGUE_META_REQUEST_SEND[catalogueId];

      if (!ifExists) {
        batchedCatalogueIds[catalogueId] = true;
      }
    });
  });

  const renderedCatalogueIds = Object.keys(batchedCatalogueIds);
  if (!renderedCatalogueIds.length) {
    return;
  }
  const oneTimeUniqueKey = `CATALOGUE_META_LISTENER_${new Date().getTime()}`;
  const apiCall = connector.CATALOGUE_ROW_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [renderedCatalogueIds],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      batchCallback: catalogueMetaBatchCallback,
      nativeStorageKey: connector.CATALOGUE_ROW_META.nativeStorageKey
    }
  });
};

const getCatalogueMeta = ({ catalogueIds = [] }) => {
  debouncer(
    { data: catalogueIds, key: CATALOGUE_META_DEBOUNCER.key },
    { time: CATALOGUE_META_DEBOUNCER.timeInMs, type: CATALOGUE_META_DEBOUNCER.type },
    catalogueMetaDebounceCallback
  );
};

// Catalogue tags listernes

const attachCatalogueTagsListener = (listener, catalogueId) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};
const removeCatalogueTagsListener = (listener, catalogueId) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};
const getCatalogueTags = catalogueId => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const apiName = connector.CATALOGUE_TAGS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [catalogueId],
    options: {
      nativeStorageKey: connector.CATALOGUE_TAGS.nativeStorageKey,
      extraData: {
        catalogueId
      }
    }
  });
};

const getCatalogueTagsFromCache = catalogueId => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  return CacheRequest.getCacheForKey(key) || undefined;
};

// CATALOGUE SETTINGS

const attachCatalogueSettingsListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeCatalogueSettingsListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const getCatalogueSettings = ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const apiName = connector.CATALOGUE_SETTINGS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [catalogueId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Catalogue link generation
const attachCatalogueLinkListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeCatalogueLinkListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const setCatalogueLinkInCache = ({ link, catalogueId }) => {
  if (!link || !catalogueId) {
    return;
  }
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkArray = link.split('/s/');
  const linkDomain = `${linkArray[0]}/s`;
  const linkSlug = linkArray[1];
  const allSlugs = linkSlug.split('/');

  const companySlug = allSlugs[0];
  const catalogueSlug = allSlugs[1];
  const randomSlug = allSlugs[2];

  const linkMeta = { linkDomain, companySlug, catalogueSlug, randomSlug };
  CacheRequest.setCacheForKey(key, linkMeta);
  return linkMeta;
};

const createBrochureLink = async brochureData => {
  try {
    const { brochureLink } = await Api.multiCatalogueBrochure(brochureData);
    const a = document.createElement('a');
    a.href = brochureLink;
    a.click();
  } catch (error) {
    Sentry.captureException(error);
  }
};

const createCataloguesLink = async (catalogueIds = []) => {
  try {
    let promiseKey = '';
    if (catalogueIds.length === 1) {
      promiseKey = catalogueIds[0];
    } else {
      promiseKey = `createCataloguesLink${Date.now()}`;
    }

    if (SHARE_LINK_PROMISE[promiseKey]) {
      return SHARE_LINK_PROMISE[promiseKey];
    }

    let resp = {};
    try {
      const linkType = 'NORMAL';
      const fetchHomepagePublished = true;
      const promise = Api.createCataloguesLink(catalogueIds, linkType, fetchHomepagePublished);
      SHARE_LINK_PROMISE[promiseKey] = promise;

      resp = await promise;
      delete SHARE_LINK_PROMISE[promiseKey];
    } catch (err) {
      delete SHARE_LINK_PROMISE[promiseKey];
      Sentry.captureException(err);
    }

    if (catalogueIds.length === 1) {
      setCatalogueLinkInCache({
        link: resp.link || '',
        catalogueId: catalogueIds[0]
      });
      setPublishToHomepageInCache({
        catalogueIds: catalogueIds,
        published: resp.areCataloguePublishedToHomepage
      });
    }

    return resp;
  } catch (error) {
    Sentry.captureException(error);
    return '';
  }
};

const getCatalogueLinkFromRemote = async ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const promiseKey = catalogueId;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;
  if (linkMeta) {
    const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
    return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  }

  if (SHARE_LINK_PROMISE[promiseKey]) {
    return SHARE_LINK_PROMISE[promiseKey];
  }

  try {
    SHARE_LINK_PROMISE[promiseKey] = true;

    const promise = Api.getCatalogueLink({
      catalogueId
    });
    SHARE_LINK_PROMISE[promiseKey] = promise;

    const { link, isCataloguePublishedToHomepage } = await promise;

    delete SHARE_LINK_PROMISE[promiseKey];

    if (link) {
      setCatalogueLinkInCache({ link, catalogueId });

      setPublishToHomepageInCache({
        catalogueIds: [catalogueId],
        published: isCataloguePublishedToHomepage
      });
    }
  } catch (error) {
    delete SHARE_LINK_PROMISE[promiseKey];
    Sentry.captureException(error);
  }
};

// HELPER METHODS
const getCatalogueIdsFromCache = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

const getCatalogueMetaFromCache = key => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const catalogueMeta = CacheRequest.getCacheForKey(`${sharedCacheKey}${key}`);

  if (!catalogueMeta) {
    return {
      title: null,
      picturesMeta: [],
      productCount: null,
      labelIds: []
    };
  }
  return catalogueMeta;
};

const getCatalogueCountFromCache = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (cache && cache.catalogueIds) {
    return (cache.catalogueIds || []).length;
  }

  return 0;
};

const sortTimestampForData = data => {
  return Object.values(data).sort((cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp);
};

const updateCatalogueIdsInCacheForLabels = ids => {
  const cacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const activeCatalogueId = getActiveCatalogueId();
  if (!Array.isArray(ids) || ids.length === 0) {
    setActiveCatalogueId(null);
    CacheRequest.setCacheForKey(`${cacheKey}`, { catalogueIds: [] });
    return;
  }
  if (ids.findIndex(element => element.catalogueId === activeCatalogueId) < 0) {
    setActiveCatalogueId(null);
  }
  const newCatalogueIds = sortTimestampForData(ids);
  CacheRequest.setCacheForKey(`${cacheKey}`, { catalogueIds: newCatalogueIds });
};

const updateLabelsInCatalogeMetaCache = ({ attachLabels, detachLabels, catalogueIds }) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  catalogueIds.forEach(catalogueId => {
    const cacheKey = `${sharedCacheKey}${catalogueId}`;
    const cachedRow = CacheRequest.getCacheForKey(cacheKey) || {};
    const catalogueMeta = { ...cachedRow };
    if (!catalogueMeta.labelIds) {
      catalogueMeta.labelIds = [];
    }
    const updatedCatalogueLabels = new Set(catalogueMeta.labelIds);
    detachLabels.forEach(labelId => updatedCatalogueLabels.delete(labelId));
    attachLabels.forEach(labelId => updatedCatalogueLabels.add(labelId));
    catalogueMeta.labelIds = Array.from(updatedCatalogueLabels);
    CacheRequest.setCacheForKey(`${cacheKey}`, catalogueMeta);
  });
};

const deleteCatalogues = async (ids, shouldDeleteFromRemote = true) => {
  const loaderKey = `deleteCatalogues${Date.now()}`;

  toggleGlobalLoader(loaderKey, true);

  if (!ids || !ids.length) {
    toggleGlobalLoader(loaderKey, false);
    return;
  }

  const cacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const catalogueRowSharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const { catalogueIds } = CacheRequest.getCacheForKey(`${cacheKey}`);

  const activeCatalogueId = getActiveCatalogueId();
  if (ids.indexOf(activeCatalogueId) > -1) {
    setActiveCatalogueId(null);
  }

  const catalogueIdsMap = {};
  catalogueIds.forEach(row => {
    catalogueIdsMap[row.catalogueId] = row;
  });
  const cacheKeysToDelete = [];
  ids.forEach(id => {
    cacheKeysToDelete.push(`${catalogueRowSharedCacheKey}${id}`);
    delete catalogueIdsMap[id];
  });
  const newCatalogueIds = sortTimestampForData(catalogueIdsMap);

  CacheRequest.deleteCacheForKeys(cacheKeysToDelete);
  CacheRequest.setCacheForKey(`${cacheKey}`, { catalogueIds: newCatalogueIds });
  const promises = [deleteCataloguesFromNative(ids)];

  if (shouldDeleteFromRemote) {
    promises.push(Api.deleteCatalogues(ids));
  }

  try {
    await Promise.all(promises);
  } catch (error) {
    throw error;
  } finally {
    toggleGlobalLoader(loaderKey, false);
  }
};

const updateLocalCatalogueTitle = ({ title, catalogueId }) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const cacheKey = `${sharedCacheKey}${catalogueId}`;
  const cachedRow = CacheRequest.getCacheForKey(cacheKey) || {};
  const copiedCachedRow = { ...cachedRow };
  copiedCachedRow.title = title;
  CacheRequest.setCacheForKey(`${cacheKey}`, copiedCachedRow);
};

const updateRemoteCatalogueTitle = async ({ catalogueId, updates }) => {
  return Api.updateCatalogue({ catalogueId, updates });
};

const changeCatalogueTitle = async ({ title, id }) => {
  const loaderKey = `changeCatalogueTitle${id}`;
  toggleGlobalLoader(loaderKey, true);

  const updates = { title };
  updateLocalCatalogueTitle({ title, catalogueId: id });
  await Promise.all([
    updateCatalogueTitleInNative({ title, catalogueId: id }),
    updateRemoteCatalogueTitle({ catalogueId: id, updates })
  ]);

  toggleGlobalLoader(loaderKey, false);
};

const getCatalogueTitle = ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;

  const cache = CacheRequest.getCacheForKey(key) || {};
  if (!cache || !cache.title) {
    return '';
  }

  return cache.title;
};

const searchCatalogueIds = searchTerm => Api.searchCatalogues(searchTerm);

const createLocalCatalogue = ({ title } = {}) => {
  const cacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const catalogueRowSharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const catalogueId = db.ref('catalogues').push().key;

  const { catalogueIds = [] } = CacheRequest.getCacheForKey(cacheKey) || {};
  const ts = new Date().getTime();
  catalogueIds.unshift({ catalogueId, sortTimestamp: ts });
  CacheRequest.setCacheForKey(cacheKey, { catalogueIds });

  const catalogueMetaKey = `${catalogueRowSharedCacheKey}${catalogueId}`;

  const catalogueMeta = {
    title,
    labelIds: selectedLabels.getAllSelectedItems(),
    picturesMeta: [],
    productCount: 0
  };

  CacheRequest.setCacheForKey(catalogueMetaKey, catalogueMeta);
  return catalogueId;
};

const createRemoteCatalogue = async meta => {
  return Api.createCatalogue(meta);
};

const createNewCatalogue = async (meta = {}) => {
  try {
    if (!meta.title) {
      return;
    }

    const eventbuskey = `createNewCatalogue${Date.now()}`;
    toggleGlobalLoader(eventbuskey, true);
    const catalogueId = createLocalCatalogue(meta);
    await Promise.all([
      createCatalogueInNative(meta, catalogueId),
      createRemoteCatalogue({
        title: meta.title,
        catalogueId,
        labelIds: selectedLabels.getAllSelectedItems()
      })
    ]);
    setActiveCatalogueId(catalogueId);
    toggleGlobalLoader(eventbuskey, false);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const createCatalogueFromFolder = () => {};

// sets ACTIVE_CATALOGUE_ID and informs about the new catalogueId
export const setActiveCatalogueId = catalogueId => {
  ACTIVE_CATALOGUE_META.catalogueId = catalogueId;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, { productId: null });
  eventbus.publish(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId);
  localStorage.setItem(ACTIVE_CATALOGUE_META.localStorageKey, catalogueId);
};

export const clearActiveCatalogueId = () => {
  ACTIVE_CATALOGUE_META.catalogueId = null;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, { productId: null });
  eventbus.publish(ACTIVE_CATALOGUE_META.eventbusKey);
  localStorage.removeItem(ACTIVE_CATALOGUE_META.localStorageKey);
};

export const getActiveCatalogueId = () => ACTIVE_CATALOGUE_META.catalogueId;

// GETTER AND SETTER FOR LAST FETCH TIMESTAMP FOR CATALOGUE SCREEN CHANGES

const getLastFetchDate = () => {
  return CATALOGUES_LAST_FETCH_TS.ts;
};

const setLastFetchDate = date => {
  CATALOGUES_LAST_FETCH_TS.ts = date;
  localStorage.setItem(CATALOGUES_LAST_FETCH_TS.localstorageKey, date);
};

// FIREBASE CHANGES LISTENER CALLBACK

const handleCatalogueChangeListener = ({ timestamp }) => {
  const localTs = getLastFetchDate();

  if (!localTs) {
    setLastFetchDate(timestamp);
    return;
  }

  if (localTs < timestamp) {
    if (CHANGES_DEBOUNCER_ID) {
      clearTimeout(CHANGES_DEBOUNCER_ID);
    }

    CHANGES_DEBOUNCER_ID = setTimeout(() => {
      onCatalogueScreenChange({ timestamp });
    }, 1000);
  }
};

export const onCatalogueScreenChange = async ({ timestamp }) => {
  try {
    CHANGES_DEBOUNCER_ID = null;
    const lastFetchTimestamp = getLastFetchDate();
    const { changes } = await Api.catalogueScreenChanges(lastFetchTimestamp);
    setLastFetchDate(timestamp);

    if (changes && changes.inserted && Object.keys(changes.inserted).length) {
      createNewCataloguesFromChangesApi(changes.inserted);
    }

    if (changes && changes.updated && Object.keys(changes.updated).length) {
      handleUpsertChanges(changes.updated);
    }

    if (changes && changes.removed && Object.keys(changes.removed).length) {
      const ids = Object.keys(changes.removed);
      deleteCatalogues(ids, false);
    }
  } catch (err) {
    // No op
  }
};

const createNewCataloguesFromChangesApi = async insertedCatalogues => {
  const addedCatalogues = [];
  const addedCatalogueMeta = {};

  if (selectedCatalogue.getAllItems().includes(Object.keys(insertedCatalogues)[0])) {
    //catalogue already exists in list
    return;
  }

  Object.keys(insertedCatalogues).forEach(catalogueId => {
    const catalogue = insertedCatalogues[catalogueId];
    addedCatalogues.push({
      catalogueId: catalogue.catalogueId,
      sortTimestamp: catalogue.sortTimestamp
    });
    addedCatalogueMeta[catalogueId] = catalogue;
    upsertChangesDataToCatalogueRowMetaCache(catalogue);
  });

  const catalogueListCacheKey = `${connector.CATALOGUE_LIST_META.cacheKey}`;

  const { catalogueIds } = CacheRequest.getCacheForKey(catalogueListCacheKey);
  const newCataloguesList = [...addedCatalogues, ...catalogueIds].sort(
    (cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp
  );

  CacheRequest.setCacheForKey(`${connector.CATALOGUE_LIST_META.cacheKey}`, {
    catalogueIds: newCataloguesList
  });

  upsertCatalogueRowInNative(addedCatalogueMeta, addedCatalogues);
};

const handleUpsertChanges = async upsertChanges => {
  const catalogueListCacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const updatedCatalogueMeta = {};

  Object.keys(upsertChanges).forEach(catalogueId => {
    const catalogueMeta = upsertChanges[catalogueId];
    updatedCatalogueMeta[catalogueId] = {
      catalogueId,
      sortTimestamp: catalogueMeta.sortTimestamp
    };
    upsertChangesDataToCatalogueRowMetaCache(catalogueMeta);
  });

  const updatedCatalogueIds = Object.keys(updatedCatalogueMeta || {});

  let allCatalogueIds =
    (CacheRequest.getCacheForKey(catalogueListCacheKey) || {}).catalogueIds || [];

  allCatalogueIds = cloneDeep(allCatalogueIds);
  const newList = allCatalogueIds
    .map(row => {
      if (updatedCatalogueIds.indexOf(row.catalogueId) > -1) {
        const sortTimestamp = updatedCatalogueMeta[row.catalogueId]
          ? updatedCatalogueMeta[row.catalogueId].sortTimestamp
          : row.sortTimestamp;

        return {
          ...row,
          catalogueId: row.catalogueId,
          sortTimestamp
        };
      }

      return row;
    })
    .sort((row1, row2) => row2.sortTimestamp - row1.sortTimestamp);

  CacheRequest.setCacheForKey(catalogueListCacheKey, { catalogueIds: newList });
  await upsertCatalogueRowInNative(upsertChanges);
};

const getAllUnselectedTags = tags => {
  const catalogueId = getActiveCatalogueId();
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const { tags: allTags } = CacheRequest.getCacheForKey(key) || {};
  const unselectedTags = [];
  Object.values(allTags || {}).forEach(tag => {
    const tagId = tag.title;

    if (tagId === 'Out of stock' || tagId === 'In stock') {
      return;
    }

    if (tags.indexOf(tagId) < 0) {
      unselectedTags.push(tagId);
    }
  });

  return unselectedTags;
};

const getCatalogueSettingsFromCache = catalogueId => {
  const defaultSettings = {
    experiments: {
      requestIdentity: null,
      verifyOTP: null,
      visitorsEnabled: null,
      catalogueStockVisibility: false,
      stockManagedFrom: '',
      orderOnOOS: '',
      oosControlledFrom: false,
      showResetModal: false,
      homeLayout: '',
      'action-button-id': ''
    },
    companySettings: {}
  };

  if (!catalogueId) {
    return defaultSettings;
  }

  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const data = CacheRequest.getCacheForKey(key);
  const companySettingsKey = `${connector.COMPANY_SETTINGS.cacheKey}`;
  let companySettings = CacheRequest.getCacheForKey(companySettingsKey);
  companySettings = companySettings ? companySettings.settings : {};
  if (data && data.experiments) {
    const stockManagedFrom =
      typeof data.experiments.stockVisibility === 'boolean' ? 'catalogue' : 'company';
    const orderOnOOS =
      typeof data.experiments.orderOnOutOfStock === 'boolean' ? 'catalogue' : 'company';

    return {
      experiments: {
        ...data.experiments,
        stockManagedFrom,
        orderOnOOS
      },
      companySettings
    };
  } else {
    return defaultSettings;
  }
};

const getCatalogueSettingsFromCacheForDropDown = catalogueId => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  return CacheRequest.getCacheForKey(key);
};

const changeCatalogueProductCover = ({ catalogueId, enable }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);

    const newCache = {
      ...cache,
      experiments: {
        ...cache.experiments,
        firstProductAsCover: !!enable
      }
    };

    CacheRequest.setCacheForKey(key, newCache);

    if (enable) {
      Api.setFirstProductCover(catalogueId);
    } else {
      Api.removeFirstProductCover(catalogueId);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

const changeCatalogueSettings = ({ catalogueId, changes }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const cache = CacheRequest.getCacheForKey(key);
  let cacheUpdates = {};

  if (typeof changes.visitorsEnabled !== 'undefined') {
    cacheUpdates.visitorsEnabled = changes.visitorsEnabled;
  }

  if (typeof changes.visitorIdentity !== 'undefined') {
    cacheUpdates.visitorIdentity = changes.visitorIdentity;
  }

  if (typeof changes.otpVerification !== 'undefined') {
    cacheUpdates.otpVerification = changes.otpVerification;
  }

  if (typeof changes.pdfDownload !== 'undefined') {
    cacheUpdates.pdfDownload = changes.pdfDownload;
  }

  if (typeof changes.productDownload !== 'undefined') {
    cacheUpdates.productDownload = changes.productDownload;
  }

  if (typeof changes.firstProductAsCover !== 'undefined') {
    cacheUpdates.firstProductAsCover = changes.firstProductAsCover;
  }

  if (typeof changes.shippingAddress !== 'undefined') {
    cacheUpdates.shippingAddress = changes.shippingAddress;
  }

  if (typeof changes.orderConfirmationCustomMessage !== 'undefined') {
    cacheUpdates.orderConfirmationCustomMessage = changes.orderConfirmationCustomMessage;
  }

  if (typeof changes.stockVisibility !== 'undefined') {
    cacheUpdates.stockVisibility = changes.stockVisibility;
  }

  if (typeof changes.orderOnOutOfStock !== 'undefined') {
    cacheUpdates.orderOnOutOfStock = changes.orderOnOutOfStock;
  }

  if (typeof changes.homeLayout !== 'undefined') {
    cacheUpdates.homeLayout = changes.homeLayout;
  }

  if (typeof changes['action-button-id'] !== 'undefined') {
    cacheUpdates['action-button-id'] = changes['action-button-id'];
  }

  const changeKey = Object.keys(changes).length > 0 ? Object.keys(changes)[0] : null;
  if (
    changeKey !== null &&
    typeof cache.experiments[changeKey] === 'object' &&
    changes[changeKey] !== null &&
    cache.experiments[changeKey] !== null
  ) {
    cacheUpdates[changeKey] = cache.experiments[changeKey];
    cacheUpdates[changeKey].finalValue = changes[changeKey];
    cacheUpdates[changeKey].managedBy = 'CATALOGUE';
  }

  const newCache = {
    ...cache,
    experiments: {
      ...cache.experiments,
      ...cacheUpdates
    }
  };

  CacheRequest.setCacheForKey(key, newCache);
  return Api.changeCatalogueSettings({ catalogueId, changes });
};

const changeOutOfStockVisibility = ({ catalogueId, outOfStockVisibility }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);
    if (typeof outOfStockVisibility !== 'undefined') {
      let newExperiments = { ...cache.experiments };
      newExperiments.stockVisibility = outOfStockVisibility;
      if (typeof outOfStockVisibility !== 'boolean') {
        newExperiments.stockManagedFrom = 'company';
      } else {
        newExperiments.stockManagedFrom = 'catalogue';
      }
      const newCache = {
        ...cache,
        experiments: newExperiments
      };
      CacheRequest.setCacheForKey(key, newCache);
    }

    return Api.changeOutOfStockVisibility({ catalogueId, outOfStockVisibility });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const changeOrderOnOutOfStock = ({ catalogueId, allowOrderOnOutOfStock }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);
    if (typeof allowOrderOnOutOfStock !== 'undefined') {
      let newExperiments = { ...cache.experiments };
      newExperiments.orderOnOutOfStock = allowOrderOnOutOfStock;
      if (typeof allowOrderOnOutOfStock !== 'boolean') {
        newExperiments.orderOnOOS = 'company';
      } else {
        newExperiments.orderOnOOS = 'catalogue';
      }

      const newCache = {
        ...cache,
        experiments: newExperiments
      };

      CacheRequest.setCacheForKey(key, newCache);
    }
    return Api.changeOrderOnOutOfStock({ catalogueId, allowOrderOnOutOfStock });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const changeMinimumOrderQuantity = async ({ catalogueId, minOrderQuantity }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key) || {};
    const oldExperiements = cache.experiments || {};
    const oldMinOrderQty = oldExperiements.minOrderQuantity || {};

    let managedBy, finalValue;
    if (typeof minOrderQuantity === 'number') {
      minOrderQuantity = Number(minOrderQuantity);
      managedBy = 'CATALOGUE';
      finalValue = minOrderQuantity;
    } else {
      //minOrderQuantity not a number, indicates a reset, pick from the cached company value
      minOrderQuantity = null;
      managedBy = 'COMPANY';
      finalValue = oldMinOrderQty.companySettingsValue;
    }

    await setMoqForEntityApi(catalogueId, MOQ_CATALOGUE_ENTITY, minOrderQuantity);
    const newCache = {
      ...cache,
      experiments: {
        ...oldExperiements,
        minOrderQuantity: {
          finalValue,
          managedBy,
          companySettingsValue: oldMinOrderQty.companySettingsValue
        }
      }
    };

    CacheRequest.setCacheForKey(key, newCache);
  } catch (error) {
    Sentry.captureException(error);
  }
};

const getAndCreateCatalogueLink = ({ catalogueId }) => {
  if (!catalogueId) {
    return;
  }

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;
  if (linkMeta) {
    const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
    return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  }

  if (SHARE_LINK_PROMISE[catalogueId]) {
    return SHARE_LINK_PROMISE[catalogueId];
  }

  createCataloguesLink([catalogueId]);
};

export const getCatalogueLinkFromCache = catalogueId => {
  if (!catalogueId) {
    return '';
  }

  if (SHARE_LINK_PROMISE[catalogueId]) {
    return SHARE_LINK_PROMISE[catalogueId];
  }

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;

  if (!linkMeta) {
    return '';
  }

  const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
  if (linkDomain && companySlug && catalogueSlug && randomSlug) {
    return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  }
  return '';
};

const getCompanySettings = async () => {
  const resp = await Api.getCompanySettings();
  setCompanySettingsInCache({ data: resp });
  return resp;
};

const isCatalogueSelected = catalogueId => {
  const activeCatalogueId = ACTIVE_CATALOGUE_META.catalogueId
    ? ACTIVE_CATALOGUE_META.catalogueId
    : '';

  return catalogueId === activeCatalogueId;
};

const getCatalogueStock = async ({ catalogueId }) => {
  const catalogueSettingsCacheKey = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const data = await Api.getCatalogueSettings(catalogueId);
  CacheRequest.setCacheForKey(catalogueSettingsCacheKey, data);
  const stockManagedFrom =
    typeof data.experiments.stockVisibility === 'boolean' ? 'catalogue' : 'company';
  if (stockManagedFrom === 'catalogue') {
    const data = CacheRequest.getCacheForKey(catalogueSettingsCacheKey);
    if (typeof data.experiments.stockVisibility === 'boolean') {
      return data.experiments.stockVisibility;
    }
  }

  return await getCompanyStockSetting();
};

const getCatalogueOrderOnOOS = async ({ catalogueId }) => {
  const catalogueSettingsCacheKey = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const data = await Api.getCatalogueSettings(catalogueId);
  CacheRequest.setCacheForKey(catalogueSettingsCacheKey, data);
  const orderOnOOSManagedFrom =
    typeof data.experiments.orderOnOutOfStock === 'boolean' ? 'catalogue' : 'company';
  if (orderOnOOSManagedFrom === 'catalogue') {
    const data = CacheRequest.getCacheForKey(catalogueSettingsCacheKey);
    if (typeof data.experiments.orderOnOutOfStock === 'boolean') {
      return data.experiments.orderOnOutOfStock;
    }
  }

  return await getCompanyOrderOnOOS();
};

const getMixpanelCatalogueProps = ({ catalogueId }) => {
  const props = {};
  const catalogueCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const catalogueCache = CacheRequest.getCacheForKey(catalogueCacheKey);
  props.catalogue_product_count = catalogueCache.productCount;
  props.catalogue_title = catalogueCache.title;

  return props;
};

const computeUniqueSortedIds = ({ catalogueIdsMeta }) => {
  const uniqueCatalogueIds = {};
  catalogueIdsMeta.forEach(row => {
    uniqueCatalogueIds[row.catalogueId] = uniqueCatalogueIds[row.catalogueId]
      ? uniqueCatalogueIds[row.catalogueId] > row.sortTimestamp
        ? uniqueCatalogueIds[row.catalogueId]
        : row.sortTimestamp
      : uniqueCatalogueIds[row.catalogueId];
  });

  return Object.keys(uniqueCatalogueIds)
    .map(id => ({
      catalogueId: id,
      sortTimestamp: uniqueCatalogueIds[id]
    }))
    .sort((cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp);
};

const cleanupAllCatalogueData = () => {
  CHANGES_DEBOUNCER_ID = null;
  ACTIVE_CATALOGUE_META.catalogueId = null;
  SHARE_LINK_PROMISE = {};
  CATALOGUE_META_REQUEST_SEND = {};

  clearCatalogueDataFromNative();
};

registerCleanupHandler(cleanupAllCatalogueData);

export default {
  OPERATION_STATUS: CacheRequest.OPERATION_STATUS,
  attachCatalogueIdsListener,
  removeCatalogueIdsListener,
  getAllCatalogueIds,
  attachCatalogueMetaListener,
  removeAttachCatalogueMetaListener,
  getCatalogueMeta,
  attachCatalogueTagsListener,
  removeCatalogueTagsListener,
  getCatalogueTags,
  getCatalogueTagsFromCache,
  attachCatalogueSettingsListener,
  removeCatalogueSettingsListener,
  getCatalogueSettings,
  attachCatalogueLinkListener,
  removeCatalogueLinkListener,
  createCataloguesLink,
  getCatalogueLinkFromRemote,
  getCatalogueMetaFromCache,
  getCatalogueCountFromCache,
  updateCatalogueIdsInCacheForLabels,
  updateLabelsInCatalogeMetaCache,
  deleteCatalogues,
  getCatalogueIdsFromCache,
  changeCatalogueTitle,
  getCatalogueTitle,
  searchCatalogueIds,
  createNewCatalogue,
  createCatalogueFromFolder,
  setActiveCatalogueId,
  getActiveCatalogueId,
  getLastFetchDate,
  getAllUnselectedTags,
  getCatalogueSettingsFromCache,
  getCatalogueSettingsFromCacheForDropDown,
  changeCatalogueSettings,
  changeOutOfStockVisibility,
  changeOrderOnOutOfStock,
  getAndCreateCatalogueLink,
  getCatalogueLinkFromCache,
  getCompanySettings,
  isCatalogueSelected,
  handleCatalogueChangeListener,
  setLastFetchDate,
  getCatalogueStock,
  getCatalogueOrderOnOOS,
  getMixpanelCatalogueProps,
  computeUniqueSortedIds,
  createBrochureLink,
  changeCatalogueProductCover,
  changeMinimumOrderQuantity
};
